import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { CONSTANTS } from 'constants/constants'
import { CAPACITY } from 'constants/forioVariables'
import { changeVariable } from 'actions/capacityActions'
import { changeValue as onChangeValue } from 'common'
import Title from 'components/TitleTablesValidation'
import { validateVariables } from 'common/utils'
import { getVariablesOfTheState } from 'common/states'
import LabelValidation from 'components/LabelTitleValidation'

const {
  ASSISTANS,
  DIRECTORS,
  PARTHERS,
  TAXES_AND_FINANCES,
  BIDDING_AND_GOVERMENT,
  TRIALS,
  POPULAR,
  REAL_STATE,
  CAPACITY_TEXT,
} = CONSTANTS

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
} = CAPACITY

const returnData = (variables, whichColumn) => [
  [
    <LabelValidation
      title={ASSISTANS}
      whichColumn={whichColumn}
      columnValidate="popularAssistance"
    />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES
        ],
    },
  ],
  [
    <LabelValidation
      title={DIRECTORS}
      whichColumn={whichColumn}
      columnValidate="popularDirectors"
    />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES
        ],
    },
  ],
  [
    <LabelValidation title={PARTHERS} whichColumn={whichColumn} columnValidate="popularPartners" />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
      value:
        variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS
        ],
    },
  ],
]
const columns = changeValue => [
  { type: 'label', name: '' },
  { type: 'inputNumber', name: TAXES_AND_FINANCES, onChange: changeValue },
  { type: 'inputNumber', name: BIDDING_AND_GOVERMENT, onChange: changeValue },
  { type: 'inputNumber', name: TRIALS, onChange: changeValue },
  { type: 'inputNumber', name: REAL_STATE, onChange: changeValue },
]

const ProductPopularSegment = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue(
    'variablesCapacityDistributionPerProductPopularSegment',
    dispatch,
    changeVariable,
  )

  const capacity = useSelector(state => state.capacity.currentValues)
  const variables = capacity.variablesCapacityDistributionPerProductPopularSegment
  const capacityNewValues = useSelector(state => state.capacity.newValues)
  const validationErrors = validateVariables(
    { ...getVariablesOfTheState(capacityNewValues) },
    CAPACITY_TEXT,
  )
  return (
    <Table
      titleColor="#9E8585"
      titleTable={
        <Title
          title="Capacity distribution per product popular segment"
          validationText="(Adding all values in a row should be less or equal to 1)"
          isError={
            validationErrors &&
            validationErrors.error &&
            (validationErrors.whichTable === POPULAR ||
              validationErrors.allTablesWithErrors === true)
          }
        />
      }
      columns={columns(changeValue)}
      data={returnData(variables, validationErrors.whichColumn)}
    />
  )
}

export default ProductPopularSegment
