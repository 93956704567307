import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { SALARIES } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const {
  BONO_DE_CONTRATACION_ABS_EN_LA_ECONOMIA_SMOOTHED,
  BONO_DE_CONTRATACION_LATERAL_EN_LA_ECONOMIA_SMOOTHED,
} = SALARIES

const { HIRING_BONUS_ASSISTANT_JOB_MARKET, HIRING_BONUS_DIRECTORS_JOB_MARKET } = CONSTANTS

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-SalariesList',
    label: HIRING_BONUS_ASSISTANT_JOB_MARKET,
    component: (
      <Typography style={classLabel}>
        {variables[BONO_DE_CONTRATACION_ABS_EN_LA_ECONOMIA_SMOOTHED]}
      </Typography>
    ),
  },
  {
    id: '2-SalariesList',
    label: HIRING_BONUS_DIRECTORS_JOB_MARKET,
    component: (
      <Typography style={classLabel}>
        {variables[BONO_DE_CONTRATACION_LATERAL_EN_LA_ECONOMIA_SMOOTHED]}
      </Typography>
    ),
  },
]
const ListElements = () => {
  const variables = useSelector(state => state.salaries.currentValues.variablesSalariesLabels)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} listTitle="Hiring bonus in market ($)" />
}
export default ListElements
