import React from 'react'
import { useSelector } from 'react-redux'
import Table from 'components/Table'
import { CONSTANTS } from 'constants/constants'
import { CAPACITY } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const {
  ASSISTANS,
  DIRECTORS,
  PARTHERS,
  TAXES_AND_FINANCES,
  BIDDING_AND_GOVERMENT,
  TRIALS,
  REAL_STATE,
} = CONSTANTS

const {
  COLA_DE_HORAS_IYF_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_SOCIO,
} = CAPACITY

const returnData = variables => [
  [
    ASSISTANS,
    variables[COLA_DE_HORAS_IYF_REGION_I_PURETE_ASISTENTE],
    variables[COLA_DE_HORAS_LYG_REGION_I_PURETE_ASISTENTE],
    variables[COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_ASISTENTE],
    variables[COLA_DE_HORAS_BRR_REGION_I_PURETE_ASISTENTE],
  ],
  [
    DIRECTORS,
    variables[COLA_DE_HORAS_IYF_REGION_I_PURETE_DIRECTOR],
    variables[COLA_DE_HORAS_LYG_REGION_I_PURETE_DIRECTOR],
    variables[COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_DIRECTOR],
    variables[COLA_DE_HORAS_BRR_REGION_I_PURETE_DIRECTOR],
  ],
  [
    PARTHERS,
    variables[COLA_DE_HORAS_IYF_REGION_I_PURETE_SOCIO],
    variables[COLA_DE_HORAS_LYG_REGION_I_PURETE_SOCIO],
    variables[COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_SOCIO],
    variables[COLA_DE_HORAS_BRR_REGION_I_PURETE_SOCIO],
  ],
]
const columns = () => [
  { type: 'label', name: '' },
  { type: 'label', name: TAXES_AND_FINANCES },
  { type: 'label', name: BIDDING_AND_GOVERMENT },
  { type: 'label', name: TRIALS },
  { type: 'label', name: REAL_STATE },
]

const HoursToCarryThroughInPopularSegment = () => {
  const variables = useSelector(
    state => state.capacity.currentValues.variablesHoursToCarryThroughInPopularSegment,
  )
  const formattedVariables = formatVariables(variables)

  return (
    <Table
      titleTable="Hours of work pending in popular segment"
      titleColor="#9E8585"
      columns={columns()}
      data={returnData(formattedVariables)}
    />
  )
}

export default HoursToCarryThroughInPopularSegment
