import React from 'react'
import '../../styles/title.css'

const MainTitleComponent = props => {
  const { hasIcon, hasReducedSubTitle, title, subtitle } = props

  let reducedSubTitle = null
  let icon = null

  if (hasIcon) {
    icon = <img src={hasIcon} alt="Pay Icon" />
  }

  if (hasReducedSubTitle) {
    reducedSubTitle = 'reducedSubTitle'
  }

  return (
    <div className="titleContainer">
      <span className="blackLine" />
      <div className="titleTextContent">
        <p className="title">{title}</p>
      </div>
      <p className={`subtitle ${reducedSubTitle}`}>{subtitle}</p>
      {icon}
    </div>
  )
}

export default MainTitleComponent
