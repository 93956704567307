import { combineReducers } from 'redux'
import { routerReducer } from 'react-router-redux'
import marketingReducer from './marketingReducer'
import headerReducer from './headerReducer'
import capacityReducer from './capacityReducer'
import experienceReducer from './experienceReducer'
import staffingReducer from './staffingReducer'
import salariesReducer from './salariesReducer'
import staffReducer from './staffReducer'
import qualityReducer from './qualityReducer'
import financesReducer from './financesReducer'
import marketReducer from './marketReducer'
import appBackendReducer from './appBackendReducer'
import userReducer from '../store/User'

const rootReducer = combineReducers({
  routing: routerReducer,
  marketing: marketingReducer,
  capacity: capacityReducer,
  salaries: salariesReducer,
  header: headerReducer,
  experience: experienceReducer,
  staffing: staffingReducer,
  quality: qualityReducer,
  finances: financesReducer,
  market: marketReducer,
  staff: staffReducer,
  user: userReducer,
  appBackend: appBackendReducer,
})

export default rootReducer
