import React from 'react'
import { useSelector } from 'react-redux'
import Table from 'components/Table'
import { CONSTANTS } from 'constants/constants'
import { MARKETING } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const {
  PRODUCT,
  POPULAR,
  CORPORATE,
  TAXES_AND_FINANCES,
  BIDDING_AND_GOVERMENT,
  TRIALS,
  REAL_STATE,
} = CONSTANTS

const {
  SALE_VENTAS_EN_DOLLARS_IYF_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_IYFF_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_FINO_ACC,
} = MARKETING

const returnData = variables => [
  [
    TAXES_AND_FINANCES,
    variables[SALE_VENTAS_EN_DOLLARS_IYF_PURETE_ACC],
    variables[SALE_VENTAS_EN_DOLLARS_IYFF_FINO_ACC],
  ],
  [
    BIDDING_AND_GOVERMENT,
    variables[SALE_VENTAS_EN_DOLLARS_LYG_PURETE_ACC],
    variables[SALE_VENTAS_EN_DOLLARS_LYG_FINO_ACC],
  ],
  [
    TRIALS,
    variables[SALE_VENTAS_EN_DOLLARS_JUICIOS_PURETE_ACC],
    variables[SALE_VENTAS_EN_DOLLARS_JUICIOS_FINO_ACC],
  ],
  [
    REAL_STATE,
    variables[SALE_VENTAS_EN_DOLLARS_BR_PURETE_ACC],
    variables[SALE_VENTAS_EN_DOLLARS_BR_FINO_ACC],
  ],
]

const columns = [
  { type: 'label', name: PRODUCT },
  { type: 'label', name: POPULAR },
  { type: 'label', name: CORPORATE },
]

const SalesThisQuarter = () => {
  const variables = useSelector(state => state.marketing.currentValues.variablesSalesThisQuater)
  const formattedVariables = formatVariables(variables)
  return (
    <Table
      titleTable="Sales this quarter in ($)"
      columns={columns}
      data={returnData(formattedVariables)}
    />
  )
}

export default SalesThisQuarter
