import { GET_TIME } from 'constants/actionTypes'
import { getVariablesByRunId } from 'services/forio/forio'
import { HEADER } from 'constants/forioVariables'
import { loadLocalStorageValue, localStorageKeys } from 'services/localstorage'

const { TIME } = HEADER

const variablesHeader = [TIME]

const getVariablesHeader = (variables, step) => ({
  type: GET_TIME,
  variables,
  step,
})

const fetchVariablesHeader = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesHeader)
    const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)
    dispatch(getVariablesHeader(variables, teamInformation.step))
  }
}

const allFuntionsFetchHeader = [fetchVariablesHeader]

export { fetchVariablesHeader, allFuntionsFetchHeader }
