import {
  GET_VARIABLES_TABLE_DECISION_SALARIES,
  CHANGE_VARIABLE_SALARIES,
  GET_VARIABLES_SALARIES_LABELS,
  GET_VARIABLES_SALARIES_CHARTS,
  CLEAR_VALUES,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'

const initialSalaries = {
  currentValues: {
    variablesTableDecisionSalaries: {},
    variablesSalariesLabels: {},
    variablesSalariesCharts: {},
  },
  newValues: {},
}

const Header = (state = initialSalaries, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialSalaries
    case GET_VARIABLES_TABLE_DECISION_SALARIES:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesTableDecisionSalaries: action.variables,
        },
      }
    case GET_VARIABLES_SALARIES_LABELS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesSalariesLabels: action.variables,
        },
      }
    case GET_VARIABLES_SALARIES_CHARTS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesSalariesCharts: action.variables,
        },
      }
    case CHANGE_VARIABLE_SALARIES:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Header
