import {
  GET_VARIBLES_RESULTS,
  GET_VARIBLES_BALANCES,
  CHANGE_VARIABLE_FINANCES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId } from 'services/forio/forio'
import { FINANCES } from 'constants/forioVariables'

const {
  VENTAS,
  COMISIONES,
  INGRESOS_TOTALES_POR_VENTAS_Y_COMISIONES,
  SALARIOS,
  BONOS_Y_OTROS,
  SEGUROS,
  MERCADEO,
  LIBROS_Y_MATERIALES,
  OTROS_GASTOS_Y_DEPRECIACIONES,
  MALPRAXIS,
  COMUNICACIONES,
  UTILIDAD_OPERATIVA,
  INTERESES_PAGADOS,
  UAI,
  IMPUESTO_DE_LA_RENTA,
  CAJA,
  CUENTAS_POR_COBRAR_SEGMENTO_FINO,
  CUENTAS_POR_COBRAR_SEGMENTO_PURETE,
  PRESTAMO,
} = FINANCES

const variablesResults = [
  VENTAS,
  COMISIONES,
  INGRESOS_TOTALES_POR_VENTAS_Y_COMISIONES,
  SALARIOS,
  BONOS_Y_OTROS,
  SEGUROS,
  MERCADEO,
  LIBROS_Y_MATERIALES,
  OTROS_GASTOS_Y_DEPRECIACIONES,
  MALPRAXIS,
  COMUNICACIONES,
  UTILIDAD_OPERATIVA,
  INTERESES_PAGADOS,
  UAI,
  IMPUESTO_DE_LA_RENTA,
]

const getVariablesResults = variables => ({
  type: GET_VARIBLES_RESULTS,
  variables,
})

const fetchVariablesResults = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesResults)
    dispatch(getVariablesResults(variables))
  }
}

const variablesBalances = [
  CAJA,
  CUENTAS_POR_COBRAR_SEGMENTO_FINO,
  CUENTAS_POR_COBRAR_SEGMENTO_PURETE,
  PRESTAMO,
]

const getVariablesBalances = variables => ({
  type: GET_VARIBLES_BALANCES,
  variables,
})

const fetchVariablesBalances = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesBalances)
    dispatch(getVariablesBalances(variables))
  }
}

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_FINANCES, payload }
}

const allFuntionsFetchFinances = [fetchVariablesResults, fetchVariablesBalances]

export { fetchVariablesResults, fetchVariablesBalances, changeVariable, allFuntionsFetchFinances }
