import React from 'react'
import { useSelector } from 'react-redux'

import { LineChart } from 'components/Charts'
import { QUALITY, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { RAZON_CLIENTES_A_ABOGADOS_TOTALES } = QUALITY
const { TIME } = HEADER
const { CLIENT_TO_LAWYER_RATIO } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: CLIENT_TO_LAWYER_RATIO,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[RAZON_CLIENTES_A_ABOGADOS_TOTALES],
      },
    ],
  }
}

const ClientToLawyerRatio = () => {
  const variables = useSelector(state => state.quality.variablesChartClientToLawyerRatio)

  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={CLIENT_TO_LAWYER_RATIO} />
  ) : null
}
export default ClientToLawyerRatio
