import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import InputNumber from 'components/InputNumber'
import { CONSTANTS } from 'constants/constants'
import { EXPERIENCE } from 'constants/forioVariables'
import { changeVariable } from 'actions/experienceActions'
import { changeValue as onChangeValue } from 'common'

const { DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES } = EXPERIENCE
const { ASSISTANT_SHIFT_HOURS } = CONSTANTS

const returnElements = (variables, changeValue) => [
  {
    id: '1-AssistantShiftLengthInHours',
    label: ASSISTANT_SHIFT_HOURS,
    component: (
      <InputNumber
        defaultValue={variables[DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES]}
        id={DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES}
        change={event => changeValue(event)}
        max={24}
      />
    ),
  },
]
const AssistantShiftLengthInHours = () => {
  const dispatch = useDispatch()
  const variables = useSelector(state => state.experience.currentValues.variablesBurnout)
  const changeValue = onChangeValue('variablesBurnout', dispatch, changeVariable)

  return <List items={returnElements(variables, changeValue)} />
}
export default AssistantShiftLengthInHours
