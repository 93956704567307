import {
  GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT,
  CHANGE_VARIABLE_CAPACITY,
  CLEAR_VALUES,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'

import { CAPACITY } from 'constants/forioVariables'

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
} = CAPACITY

const initialCapacity = {
  currentValues: {
    variablesCapacityDistributionPerProductPopularSegment: {},
    variablesCapacityDistributionPerProductCorporateSegment: {},
    variablesHoursToCarryThroughInPopularSegment: {},
    variablesHoursToCarryThroughInCorporateSegment: {},
    variablesChartAccountsAwaitingBillingInPopularSegment: {},
    variablesChartAccountsAwaitingBillingInCorporateSegment: {},
    variablesCapacityDistributionPerSegment: {},
  },
  newValues: {},
  variablesCapacityDistributionPerSegment: {},
}

const Capacity = (state = initialCapacity, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialCapacity
    case GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesCapacityDistributionPerSegment: action.variables,
        },
        variablesCapacityDistributionPerSegment: {
          ...action.variables,
          [`${DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE}`]:
            1 -
            action.variables[`${DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO}`],
        },
      }
    case GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesCapacityDistributionPerProductPopularSegment: action.variables,
        },
      }
    case GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesCapacityDistributionPerProductCorporateSegment: action.variables,
        },
      }
    case GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesHoursToCarryThroughInPopularSegment: action.variables,
        },
      }
    case GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesHoursToCarryThroughInCorporateSegment: action.variables,
        },
      }
    case GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartAccountsAwaitingBillingInPopularSegment: action.variables,
        },
      }
    case GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartAccountsAwaitingBillingInCorporateSegment: action.variables,
        },
      }
    case CHANGE_VARIABLE_CAPACITY:
      if (
        action.payload.variable === 'variablesCapacityDistributionPerSegment' &&
        action.payload.id === DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE
      ) {
        return {
          ...state,
          newValues: {
            ...state.newValues,
            [`${action.payload.variable}`]: {
              ...state.newValues[`${action.payload.variable}`],
              [`${action.payload.id}`]: action.payload.value,
            },
          },
          variablesCapacityDistributionPerSegment: {
            ...state.variablesCapacityDistributionPerSegment,
            [`${action.payload.id}`]: action.payload.value,
          },
        }
      } else {
        return {
          ...state,
          newValues: {
            ...state.newValues,
            [`${action.payload.variable}`]: {
              ...state.currentValues[`${action.payload.variable}`],
              ...state.newValues[`${action.payload.variable}`],
              [`${action.payload.id}`]: action.payload.value,
            },
          },
        }
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
        variablesCapacityDistributionPerSegment: {
          ...state.currentValues.variablesCapacityDistributionPerSegment,
          [`${DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE}`]:
            1 -
            state.currentValues.variablesCapacityDistributionPerSegment[
              `${DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO}`
            ],
        },
      }
    default:
      return state
  }
}

export default Capacity
