import {
  GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT,
  CHANGE_VARIABLE_CAPACITY,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { CAPACITY } from 'constants/forioVariables'

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_IYF_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_IYF_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_IYF_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_LYG_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_LYG_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_LYG_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_JUICIOS_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_BRR_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_BRR_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_BRR_REGION_I_FINO_SOCIOS,
  CUENTA_SEGMENTO_PURETE_EN_ESPERA_DE_FACTURACION,
  CUENTA_SEGMENTO_FINO_EN_ESPERA_DE_FACTURACION,
} = CAPACITY

const variablesCapacityDistributionPerProductPopularSegment = [
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
]

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_CAPACITY, payload }
}

const variablesCapacityDistributionPerSegment = [
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE,
]

const getVariablesCapacityDistributionPerSegment = variables => ({
  type: GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT,
  variables,
})

const fetchVariablesCapacityDistributionPerSegment = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesCapacityDistributionPerSegment)
    dispatch(getVariablesCapacityDistributionPerSegment(variables))
  }
}

const getVariablesCapacityDistributionPerProductPopularSegment = variables => ({
  type: GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT,
  variables,
})

const fetchVariablesCapacityDistributionPerProductPopularSegment = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(
      variablesCapacityDistributionPerProductPopularSegment,
    )
    dispatch(getVariablesCapacityDistributionPerProductPopularSegment(variables))
  }
}

const variablesCapacityDistributionPerProductCorporateSegment = [
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
]

const getVariablesCapacityDistributionPerProductCorporateSegment = variables => ({
  type: GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT,
  variables,
})

const fetchVariablesCapacityDistributionPerProductCorporateSegment = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(
      variablesCapacityDistributionPerProductCorporateSegment,
    )
    dispatch(getVariablesCapacityDistributionPerProductCorporateSegment(variables))
  }
}

const variablesHoursToCarryThroughInPopularSegment = [
  COLA_DE_HORAS_IYF_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_IYF_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_LYG_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_SOCIO,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_ASISTENTE,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_DIRECTOR,
  COLA_DE_HORAS_BRR_REGION_I_PURETE_SOCIO,
]

const getVariablesHoursToCarryThroughInPopularSegment = variables => ({
  type: GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT,
  variables,
})

const fetchVariablesHoursToCarryThroughInPopularSegment = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(
      variablesHoursToCarryThroughInPopularSegment,
    )
    dispatch(getVariablesHoursToCarryThroughInPopularSegment(variables))
  }
}

const variablesHoursToCarryThroughInCorporateSegment = [
  COLA_DE_HORAS_IYF_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_IYF_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_IYF_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_LYG_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_LYG_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_LYG_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_JUICIOS_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_SOCIOS,
  COLA_DE_HORAS_BRR_REGION_I_ASISTENTE_FINO,
  COLA_DE_HORAS_BRR_REGION_I_FINO_DIRECTORES,
  COLA_DE_HORAS_BRR_REGION_I_FINO_SOCIOS,
]

const getVariablesHoursToCarryThroughInCorporateSegment = variables => ({
  type: GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT,
  variables,
})

const fetchVariablesHoursToCarryThroughInCorporateSegment = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(
      variablesHoursToCarryThroughInCorporateSegment,
    )
    dispatch(getVariablesHoursToCarryThroughInCorporateSegment(variables))
  }
}

const variablesChartAccountsAwaitingBillingInPopularSegment = [
  CUENTA_SEGMENTO_PURETE_EN_ESPERA_DE_FACTURACION,
]

const getVariablesChartAccountsAwaitingBillingInPopularSegment = variables => ({
  type: GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT,
  variables,
})

const fetchVariablesChartAccountsAwaitingBillingInPopularSegment = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(
      variablesChartAccountsAwaitingBillingInPopularSegment,
    )
    dispatch(getVariablesChartAccountsAwaitingBillingInPopularSegment(variables))
  }
}

const variablesChartAccountsAwaitingBillingInCorporateSegment = [
  CUENTA_SEGMENTO_FINO_EN_ESPERA_DE_FACTURACION,
]

const getVariablesChartAccountsAwaitingBillingInCorporateSegment = variables => ({
  type: GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT,
  variables,
})

const fetchVariablesChartAccountsAwaitingBillingInCorporateSegment = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(
      variablesChartAccountsAwaitingBillingInCorporateSegment,
    )
    dispatch(getVariablesChartAccountsAwaitingBillingInCorporateSegment(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFuntionsFetchCapacity = [
  fetchVariablesCapacityDistributionPerProductPopularSegment,
  fetchVariablesCapacityDistributionPerProductCorporateSegment,
  fetchVariablesHoursToCarryThroughInPopularSegment,
  fetchVariablesHoursToCarryThroughInCorporateSegment,
  fetchVariablesChartAccountsAwaitingBillingInPopularSegment,
  fetchVariablesChartAccountsAwaitingBillingInCorporateSegment,
  fetchVariablesCapacityDistributionPerSegment,
]

export {
  fetchVariablesCapacityDistributionPerProductPopularSegment,
  fetchVariablesCapacityDistributionPerProductCorporateSegment,
  fetchVariablesHoursToCarryThroughInPopularSegment,
  fetchVariablesHoursToCarryThroughInCorporateSegment,
  fetchVariablesChartAccountsAwaitingBillingInPopularSegment,
  fetchVariablesChartAccountsAwaitingBillingInCorporateSegment,
  fetchVariablesCapacityDistributionPerSegment,
  allFuntionsFetchCapacity,
  changeVariable,
  clearNewValues,
}
