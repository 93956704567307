import * as actions from '../constants/actionTypes'

const initialState = {
  createTeam: {
    creatingTeam: false,
    createTeamError: '',
  },
  joinTeam: {
    joiningTeam: false,
    joinTeamError: '',
    joinTeamSuccessfully: false,
  },
  joinTeamLink: '',
  getSimulations: {
    loading: false,
    errorMessage: '',
    simulations: null,
  },
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actions.CREATE_TEAM:
      return {
        ...state,
        createTeam: {
          createTeamError: '',
          creatingTeam: true,
        },
      }
    case actions.CREATE_TEAM_SUCCESSFUL:
      return {
        ...state,
        createTeam: {
          createTeamError: '',
          creatingTeam: false,
        },
        joinTeamLink: action.payload,
      }
    case actions.CREATE_TEAM_FAILED:
      return {
        ...state,
        createTeam: {
          creatingTeam: false,
          createTeamError: action.payload,
        },
      }
    case actions.RESET_CREATE_TEAM:
      return {
        ...state,
        createTeam: {
          ...initialState.createTeam,
        },
        joinTeamLink: '',
      }
    case actions.JOIN_TEAM:
      return {
        ...state,
        joinTeam: {
          joiningTeam: true,
          joinTeamError: '',
          joinTeamSuccessfully: false,
        },
      }
    case actions.JOIN_TEAM_SUCCESSFUL:
      return {
        ...state,
        joinTeam: {
          joiningTeam: false,
          joinTeamError: '',
          joinTeamSuccessfully: true,
        },
        joinTeamLink: action.payload,
      }
    case actions.JOIN_TEAM_FAILED:
      return {
        ...state,
        joinTeam: {
          joiningTeam: false,
          joinTeamError: action.payload,
          joinTeamSuccessfully: false,
        },
      }
    case actions.GET_SIMULATIONS:
      return {
        ...state,
        getSimulations: {
          loading: true,
          errorMessage: '',
          simulations: null,
        },
      }
    case actions.GET_SIMULATIONS_SUCCESSFUL:
      return {
        ...state,
        getSimulations: {
          loading: false,
          errorMessage: '',
          simulations: action.payload,
        },
      }
    case actions.GET_SIMULATIONS_FAILED:
      return {
        ...state,
        getSimulations: {
          loading: false,
          errorMessage: action.payload,
          simulations: null,
        },
      }
    default:
      return state
  }
}
