import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/capacityActions'
import Box from '@material-ui/core/Box'

import { CapacityDistributionPerSegment } from './RangeSlider'
import {
  CapacityDistributionPerProductPopularSegment,
  CapacityDistributionPerProductCorporateSegment,
} from './TablesDecisions'
import {
  HoursToCarryThroughInPopularSegment,
  HoursToCarryThroughInCorporateSegment,
} from './Tables'
import {
  AccountsAwaitingBillingInPopularSegment,
  AccountsAwaitingBillingInCorporateSegment,
} from './Graphics'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#3A4149',
    border: '1px solid #23282c',
    boxShadow: 'none',
    marginTop: '10px',
  },
  marginTop: {
    marginTop: '10px',
  },
})

const Capacity = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateCapacity = useSelector(state => state.capacity.currentValues)
  if (validateStates(stateCapacity)) {
    return <Loading />
  }
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <CapacityDistributionPerSegment />
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <div>
            <h2>Popular</h2>
          </div>
          <Box>
            <CapacityDistributionPerProductPopularSegment />
          </Box>
          <Box className={classes.marginTop}>
            <HoursToCarryThroughInPopularSegment />
          </Box>
          <Box>
            <AccountsAwaitingBillingInPopularSegment />
          </Box>
        </Box>
        <Box className="elementWrapper elementToRight">
          <div>
            <h2>Corporations</h2>
          </div>
          <Box>
            <CapacityDistributionPerProductCorporateSegment />
          </Box>
          <Box className={classes.marginTop}>
            <HoursToCarryThroughInCorporateSegment />
          </Box>
          <Box>
            <AccountsAwaitingBillingInCorporateSegment />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Capacity
