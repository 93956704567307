const CONSTANTS = {
  PRODUCT: 'Product',
  POPULAR: 'Popular',
  CORPORATE: 'Corporate',
  ASSISTANTS: 'Assistants',
  DIRECTORS: 'Directors',
  PARTHERS: 'Partners',
  TAXES_AND_FINANCES: 'Taxes and Finances',
  BIDDING_AND_GOVERMENT: 'Bidding and Government',
  TRIALS: 'Trials',
  REAL_STATE: 'Real Estate',
  TOTAL_NUMBER_OF_BRANCH_OFFICES: 'Total number of branch offices',
  OPEN_NEW_BRANCH_OFFICES: 'Open new branch offices',
  CLOSE_BRANCH_OFFICES: 'Close branch offices',
  PERCENTAGE_OF_REVENUE_SPENT_IN_MARKETING: 'Fraction of revenue to spend in marketing',
  DAYS_BEFORE_PAYMENT: 'Days before payment',
  POPULAR_SEGMENT: 'Popular Segment',
  CORPORATE_SEGMENT: 'Corporate Segment',
  ASSISTANS_SALARY: "Assistant's Salary",
  DIRECTORS_SALARY: "Director's Salary",
  PARTHERS_SALARY: "Partner's Salary",
  STAFF_SALARY: 'Support Staff Salary',
  HIRING_BONUS_ASSISTANT: 'Hiring Bonus Assistant',
  HIRING_BONUS_DIRECTORS: 'Hiring Bonus Directors',
  MEAN_ABILITY: 'Mean Ability',
  STEPS: 90,
  PARTNERS: 'Partners',
  ASSISTANT_SHIFT_HOURS: 'Assistant shift length in hours',
  DIRECTOR_SHIFT_HOURS: 'Director shift length in hours',
  FISRT_YEAR_HOURS: 'First year of contract',
  SECOND_YEAR_HOURS: 'Second year of contract',
  THIRD_YEAR_HOURS: 'Third year of contract',
  HIRING_ASSITANT: 'Hiring of Assistant Lawyers',
  HIRING_DIRECTORS: 'Hiring of Directors',
  DESIRED_INEXPERIENCED_SUPPORT: 'Desired Inexperienced Support Staff',
  PROMOTION_TO_DIRECTOR: 'Promotions to Director',
  PROMOTION_TO_PARTNER: 'Promotions to Partner',
  CAPACITY_DISTRIBUTION_PER_SEGMENT: 'Capacity distribution per segment',
  PERCEIVED_REPUTATION: 'Perceived Reputation',
  PERCEIVED_QUALITY: 'Perceived Quality',
  CLIENT_TO_LAWYER_RATIO: 'Client to Lawyer Ratio',
  FRACTION_OF_TIME_USED_IN_MENTORSHIP: 'Mentorship',
  FRACTION_OF_TIME_USED_TO_ACQUIRED_CLIENTS: 'Client Acquisition',
  SALES: 'Sales',
  COMMISSION: 'Commission',
  REVENUE: 'Revenue',
  SALARIES: 'Salaries',
  BONUSES_AND_OTHERS: 'Bonuses and others',
  INSURANCES: 'Insurances',
  MARKETING: 'Marketing',
  BOOKS_AND_MATERIALS: 'Books and Materials',
  MISCELANEOUS_EXPENSES_AND_DEPRECIATIONS: 'Miscellaneous expenses and depreciations',
  MALPRACTICE_EXPENCES: 'Malpractice Expenses',
  COMMUNICATIONS: 'Communications',
  OPERATING_INCOME: 'Operating Income',
  PAID_INTEREST: 'Paid interest',
  PROFIT_BEFORE_TAXES: 'Profit Before Taxes',
  TAXES: 'Taxes',
  CASH: 'Cash',
  ACCOUNT_RECEIVABLE_CORPORATE_SEGMENT: 'Account Receivable Corporate Segment',
  ACCOUNT_RECEIVABLE_POPULAR_SEGMENT: 'Account Receivable Popular Segment',
  LOAN: 'Loan',
  COMPETITION_BRANCH_OFFICE: 'Competition branch offices',
  PREFERRED_SEGMENT: 'Preferred Segment',
  STAFF: 'Staff',
  HIRING_BONUS_ASSISTANT_JOB_MARKET: 'Hiring Bonus Assistant in Job Market',
  HIRING_BONUS_DIRECTORS_JOB_MARKET: 'Hiring Bonus Directors in Job Market',
  NUMBER_OF_PARTHERS: 'Number of Partners',
  RESIGNATIONS: 'Resignations',
  PROMOTIONS_TO_ASSOCIATE: 'Promotions to Partner',
  LAYOFFS: 'Layoffs',
  NUMBER_OF_DIRECTORS: 'Number of Directors',
  NUMBER_OF_ASSISTANTS: 'Number of Assistants',
  ASSISTANS: 'Assistants',
  INEXPERIENCED_SUPPORT_STAFF: 'Inexperienced Support Staff',
  EXPERIENCED_SUPPORT_STAFF: 'Experienced Support Staff',
  SUPPORT_STAFF: 'Support Staff',
  CAPACITY_TEXT: 'capacity',
  EXPERIENCE_TEXT: 'Experience',
  TOTAL_CUSTOMER_POPULAR_SEGMENT: 'Total customers in popular segment',
  TOTAL_CUSTOMER_CORPORATE_SEGMENT: 'Total customers in corporate segment',
}

export { CONSTANTS }
