import { cloneDeep } from 'lodash'
import { CAPACITY, EXPERIENCE } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { POPULAR, CORPORATE, CAPACITY_TEXT, EXPERIENCE_TEXT } = CONSTANTS

const {
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
} = EXPERIENCE

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
} = CAPACITY

const formatNumber = variable => Number(variable.toFixed(2))

const formatVariables = variables => {
  const variablesCopy = cloneDeep(variables)
  Object.keys(variablesCopy).forEach(key => {
    variablesCopy[key] = formatNumber(variablesCopy[key]).toLocaleString()
  })
  return variablesCopy
}

const roundVariables = variables => {
  const variablesCopy = cloneDeep(variables)
  Object.keys(variablesCopy).forEach(key => {
    variablesCopy[key] = Math.round(variablesCopy[key])
  })
  return variablesCopy
}

const getWhichColumnHaveError = (columns, comparationType) => {
  const comparation = item =>
    comparationType === '!==' ? Object.values(item)[0] !== 1 : Object.values(item)[0] > 1
  const columnsWithErrors = columns.filter(
    item => item && Object.values(item)[0] && comparation(item),
  )
  return columnsWithErrors.map(item => Object.keys(item)[0])
}

const validationsCapacity = variablesToValidate => {
  let error = {}
  let numberTablesWithErrors = 0
  // Validate capacity popular variables
  const popularAssistance =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES
    ]

  const popularDirectors =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES
    ]

  const popularPartners =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS
    ]

  // validate capacity corporate variables
  const corporateAssitance =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO
    ]

  const corporateDirectors =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO
    ]

  const corporatePartners =
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO
    ] +
    variablesToValidate[
      DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO
    ]

  const columnsValidate = [
    { corporateAssitance },
    { corporateDirectors },
    { corporatePartners },
    { popularAssistance },
    { popularDirectors },
    { popularPartners },
  ]

  if (
    (popularAssistance && Number(popularAssistance.toFixed(2)) !== 1) ||
    (popularDirectors && Number(popularDirectors.toFixed(2)) !== 1) ||
    (popularPartners && Number(popularPartners.toFixed(2)) !== 1)
  ) {
    numberTablesWithErrors += 1
    error = {
      message: 'There was an error, please check values',
      error: true,
      whichTable: POPULAR,
      AllTable: false,
      whichColumn: getWhichColumnHaveError(columnsValidate, '!=='),
    }
  }

  if (
    (corporateAssitance && Number(corporateAssitance.toFixed(2)) !== 1) ||
    (corporateDirectors && Number(corporateDirectors.toFixed(2)) !== 1) ||
    (corporatePartners && Number(corporatePartners.toFixed(2)) !== 1)
  ) {
    numberTablesWithErrors += 1

    error = {
      message: 'There was an error, please check values',
      error: true,
      whichTable: CORPORATE,
      whichColumn: getWhichColumnHaveError(columnsValidate, '!=='),
    }
  }

  return {
    ...error,
    allTablesWithErrors: numberTablesWithErrors === 2,
  }
}

const validationsExperience = variablesToValidate => {
  let error = {}

  // Validate experience Partners
  const experiencePartners =
    variablesToValidate[
      DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA
    ] +
    variablesToValidate[
      DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES
    ]

  const experienceDirectors =
    variablesToValidate[DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR]
  const columnsValidate = [{ experiencePartners }, { experienceDirectors }]

  if (
    (experiencePartners && Number(experiencePartners.toFixed(2)) > 1) ||
    (experienceDirectors && Number(experienceDirectors.toFixed(2)) > 1)
  ) {
    error = {
      message: 'There was an error, please check values',
      error: true,
      whichTable: EXPERIENCE_TEXT,
      whichColumn: getWhichColumnHaveError(columnsValidate, '>'),
    }
  }

  return error
}

const validateVariables = (variablesToValidate, moduleToValidate) => {
  switch (moduleToValidate) {
    case CAPACITY_TEXT:
      return validationsCapacity(variablesToValidate)
    case EXPERIENCE_TEXT:
      return validationsExperience(variablesToValidate)
    default:
      return {}
  }
}

const getVariablesChanged = (currentValues, newValues) => {
  let variablesChanged = {}
  for (const variable in newValues) {
    if (currentValues[`${variable}`] !== newValues[`${variable}`]) {
      variablesChanged = {
        ...variablesChanged,
        [`${variable}`]: newValues[`${variable}`],
      }
    }
  }

  return variablesChanged
}

export { formatNumber, formatVariables, validateVariables, roundVariables, getVariablesChanged }
