const UPDATE_ALL_VARIABLES = 'UPDATE_ALL_VARIABLES'
const CHANGE_VARIABLE_MARKETING = 'CHANGE_VARIABLE_MARKETING'
const CHANGE_VARIABLE_CAPACITY = 'CHANGE_VARIABLE_CAPACITY'
const GET_VARIBLES_HOUR_TO_CARRY = 'GET_VARIBLES_HOUR_TO_CARRY'
const GET_VARIBLES_SALES_THIS_QUATER = 'GET_VARIBLES_SALES_THIS_QUATER'
const GET_VARIBLES_LIST = 'GET_VARIBLES_LIST'
const GET_VARIBLES_CHART_POPULAR_SEGMENT = 'GET_VARIBLES_CHART_POPULAR_SEGMENT'
const GET_VARIBLES_CHART_CORPORATE_SEGMENT = 'GET_VARIBLES_CHART_CORPORATE_SEGMENT'
const GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT =
  'GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT'
const GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT =
  'GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT'
const GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT =
  'GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT'
const GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT =
  'GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT'
const GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT =
  'GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT'
const GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT =
  'GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT'
const GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT =
  'GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT'
const GET_TIME = 'GET_TIME'
const GET_VARIABLES_TABLE_DECISION_SALARIES = 'GET_VARIABLES_TABLE_DECISION_SALARIES'
const CHANGE_VARIBLE = 'CHANGE_VARIBLE'
const GET_VARIBLES_EFECTIVE_TIME_AVAILABLE = 'GET_VARIBLES_EFECTIVE_TIME_AVAILABLE'
const GET_VARIBLES_MEAN_ABILITY = 'GET_VARIBLES_MEAN_ABILITY'
const GET_VARIBLES_MEAN_EXPERIENCE = 'GET_VARIBLES_MEAN_EXPERIENCE'
const GET_VARIBLES_BURNOUT = 'BURNOUT'
const CHANGE_EXPERIENCE_VARIABLE = 'CHANGE_EXPERIENCE_VARIABLE'
const GET_EXPERIENCE_VARIABLES_LIST = 'GET_EXPERIENCE_VARIABLES_LIST'
const GET_FIRED_DECISION = 'GET_FIRED_DECISION'
const CHANGE_VARIABLE_STAFFING = 'CHANGE_VARIABLE_STAFFING'
const GET_HIRING_DECISIONS = 'GET_HIRING_DECISIONS'
const GET_PROMOTIONS = 'GET_PROMOTIONS'
const GET_VARIBLES_PERCEIVED_REPUTATION = 'GET_VARIBLES_PERCEIVED_REPUTATION'
const GET_VARIBLES_PERCEIVED_QUALITY = 'GET_VARIBLES_PERCEIVED_QUALITY'
const GET_VARIBLES_CLIENT_TO_LAWYER_RATIO = 'GET_VARIBLES_CLIENT_TO_LAWYER_RATIO'
const CHANGE_VARIABLE_QUALITY = 'CHANGE_VARIABLE_QUALITY'
const GET_VARIABLE_MENTORSHIP = 'GET_VARIABLE_MEMTORSHIP'
const GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS = 'GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS'
const GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS = 'GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS'
const GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS = 'GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS'
const GET_VARIABLES_PARTNERS_LIST = 'GET_VARIABLES_PARTNERS_LIST'
const GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS = 'GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS'
const GET_VARIABLES_DIRECTORS_LIST = 'GET_VARIABLES_DIRECTORS_LIST'
const GET_VARIABLES_AB = 'GET_VARIABLES_AB'
const GET_VARIABLES_LIST_AB = 'GET_VARIABLES_LIST_AB'
const CLEAR_VALUES = 'CLEAR_VALUES'
const GET_VARIBLES_RESULTS = 'RESULTS'
const GET_VARIBLES_BALANCES = 'BALANCES'
const CHANGE_VARIABLE_FINANCES = 'CHANGE_VARIABLE_FINANCES'
const GET_MARKET_VARIABLES = 'GET_MARKET_VARIABLES'
const CHANGE_VARIABLE_SALARIES = 'CHANGE_VARIABLE_SALARIES'
const GET_VARIABLES_SALARIES_LABELS = 'GET_VARIABLES_SALARIES_LABELS'
const GET_VARIABLES_SALARIES_CHARTS = 'GET_VARIABLES_SALARIES_CHARTS'
const GET_VARIABLE_MARKET_LABEL = 'GET_VARIABLE_MARKET_LABEL'
const CLEAR_NEW_VALUES = 'CLEAR_NEW_VALUES'

const CREATE_TEAM = 'CREATE_TEAM'
const CREATE_TEAM_SUCCESSFUL = 'CREATE_TEAM_SUCCESSFUL'
const CREATE_TEAM_FAILED = 'CREATE_TEAM_FAILED'
const RESET_CREATE_TEAM = 'RESET_CREATE_TEAM'
const JOIN_TEAM = 'JOIN_TEAM'
const JOIN_TEAM_SUCCESSFUL = 'JOIN_TEAM_SUCCESSFUL'
const JOIN_TEAM_FAILED = 'JOIN_TEAM_FAILED'
const GET_SIMULATIONS = 'GET_SIMULATIONS'
const GET_SIMULATIONS_SUCCESSFUL = 'GET_SIMULATIONS_SUCCESSFUL'
const GET_SIMULATIONS_FAILED = 'GET_SIMULATIONS_FAILED'

export {
  UPDATE_ALL_VARIABLES,
  GET_VARIBLES_HOUR_TO_CARRY,
  GET_VARIBLES_SALES_THIS_QUATER,
  GET_VARIBLES_LIST,
  GET_TIME,
  GET_VARIBLES_CHART_POPULAR_SEGMENT,
  GET_VARIBLES_CHART_CORPORATE_SEGMENT,
  GET_VARIBLES_CAPACITY_DISTRIBUTION_PER_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_POPULAR_SEGMENT,
  GET_VARIABLES_CAPACITY_DISTRIBUTION_PER_PRODUCT_CORPORATE_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_POPULAR_SEGMENT,
  GET_VARIABLES_HOURS_TO_CARRY_THROUGHIN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_CORPORATE_SEGMENT,
  GET_VARIBLES_ACCOUNTS_AWAITING_BILLING_IN_POPULAR_SEGMENT,
  CHANGE_VARIBLE,
  GET_VARIBLES_EFECTIVE_TIME_AVAILABLE,
  GET_VARIBLES_MEAN_ABILITY,
  GET_VARIBLES_MEAN_EXPERIENCE,
  GET_VARIBLES_BURNOUT,
  CHANGE_EXPERIENCE_VARIABLE,
  GET_EXPERIENCE_VARIABLES_LIST,
  CHANGE_VARIABLE_MARKETING,
  CHANGE_VARIABLE_CAPACITY,
  GET_FIRED_DECISION,
  CHANGE_VARIABLE_STAFFING,
  CLEAR_VALUES,
  GET_VARIABLES_TABLE_DECISION_SALARIES,
  GET_HIRING_DECISIONS,
  GET_PROMOTIONS,
  GET_VARIBLES_PERCEIVED_REPUTATION,
  GET_VARIBLES_PERCEIVED_QUALITY,
  GET_VARIBLES_CLIENT_TO_LAWYER_RATIO,
  CHANGE_VARIABLE_QUALITY,
  GET_VARIABLE_MENTORSHIP,
  GET_VARIBLES_RESULTS,
  GET_VARIBLES_BALANCES,
  CHANGE_VARIABLE_FINANCES,
  GET_MARKET_VARIABLES,
  CHANGE_VARIABLE_SALARIES,
  GET_VARIABLES_SALARIES_LABELS,
  GET_VARIABLES_SALARIES_CHARTS,
  GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS,
  GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS,
  GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS,
  GET_VARIABLES_PARTNERS_LIST,
  GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS,
  GET_VARIABLES_DIRECTORS_LIST,
  GET_VARIABLES_AB,
  GET_VARIABLES_LIST_AB,
  GET_VARIABLE_MARKET_LABEL,
  CREATE_TEAM,
  CREATE_TEAM_SUCCESSFUL,
  CREATE_TEAM_FAILED,
  RESET_CREATE_TEAM,
  JOIN_TEAM,
  JOIN_TEAM_SUCCESSFUL,
  JOIN_TEAM_FAILED,
  CLEAR_NEW_VALUES,
  GET_SIMULATIONS,
  GET_SIMULATIONS_SUCCESSFUL,
  GET_SIMULATIONS_FAILED,
}
