import React from 'react'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(() => ({
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
    color: 'red',
  },
}))

const Title = ({ title, validationText, isError }) => {
  const classes = useStyle()

  return (
    <div>
      <Box style={{ width: '100%', paddingRight: '10px' }}>
        <span>{title}</span>
      </Box>
      <Box style={{ width: '100%', paddingRight: '10px' }}>
        <span className={isError ? classes.title : null}>{validationText}</span>
      </Box>
    </div>
  )
}
export default Title
