import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Loading from 'components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/marketingActions'
import { HoursToCarry, SalesThisQuarter } from './TablesDecisions'
import { ChartPopularSegment, ChartCorporateSegment } from './Graphics'
import ListElements from './List'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#3A4149',
    border: '1px solid #23282c',
    boxShadow: 'none',
  },
  sectionTitle: {},
})

const Marketing = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateMarketing = useSelector(state => state.marketing.currentValues)
  if (validateStates(stateMarketing)) {
    return <Loading />
  }

  return (
    <div>
      <h2 className={classes.sectionTitle}>Market Decisions</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <HoursToCarry />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ListElements />
        </Box>
      </Box>
      <h2 className={classes.sectionTitle}>Market Share</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ChartPopularSegment />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ChartCorporateSegment />
        </Box>
      </Box>
      <h2 className={classes.sectionTitle}>Sales this Quarter</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <SalesThisQuarter />
        </Box>
      </Box>
    </div>
  )
}

export default Marketing
