import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { MARKET, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_FINO } = MARKET
const { TIME } = HEADER
const { TAXES_AND_FINANCES } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: TAXES_AND_FINANCES,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_FINO],
      },
    ],
  }
}

const PremiumMarketTaxesAndFinances = () => {
  const variables = useSelector(state => state.market.variablesMarketCharts)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart
      data={returnData(variables, time)}
      titleColor="#758C5E"
      titleChart={TAXES_AND_FINANCES}
    />
  ) : null
}
export default PremiumMarketTaxesAndFinances
