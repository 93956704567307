import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { STAFF } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const { PROMOTION_TO_DIRECTOR, RESIGNATIONS, LAYOFFS, NUMBER_OF_ASSISTANTS } = CONSTANTS

const {
  PROMOVIDOS_A_DIRECTORES,
  RENUNCIAS_ASISTENTES,
  DESPIDOS_ASISTENTES,
  TOTAL_DE_ASISTENTES,
} = STAFF

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-AbList',
    label: PROMOTION_TO_DIRECTOR,
    component: <Typography style={classLabel}>{variables[PROMOVIDOS_A_DIRECTORES]}</Typography>,
  },
  {
    id: '2-AbList',
    label: RESIGNATIONS,
    component: <Typography style={classLabel}>{variables[RENUNCIAS_ASISTENTES]}</Typography>,
  },
  {
    id: '3-AbList',
    label: LAYOFFS,
    component: <Typography style={classLabel}>{variables[DESPIDOS_ASISTENTES]}</Typography>,
  },
  {
    id: '4-AbList',
    label: NUMBER_OF_ASSISTANTS,
    component: <Typography style={classLabel}>{variables[TOTAL_DE_ASISTENTES]}</Typography>,
  },
]
const AbList = () => {
  const variables = useSelector(state => state.staff.variablesAbList)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default AbList
