import React from 'react'
import { useSelector } from 'react-redux'
import { STAFF } from 'constants/forioVariables'
import Table from 'components/Table'
import { makeStyles, Typography } from '@material-ui/core'

const {
  SOCIOS_1,
  SOCIOS_2,
  SOCIOS_3,
  SOCIOS_4,
  SOCIOS_5,
  SOCIOS_6,
  SOCIOS_7,
  SOCIOS_8,
  SOCIOS_9,
  SOCIOS_10,
  SOCIOS_11,
  SOCIOS_12,
  SOCIOS_13,
  SOCIOS_14,
  SOCIOS_15,
  SOCIOS_16,
  SOCIOS_17,
  SOCIOS_18,
  SOCIOS_19,
  SOCIOS_20,
  SOCIOS_21,
  SOCIOS_22,
  SOCIOS_23,
  SOCIOS_24,
  SOCIOS_25,
  SOCIOS_26,
  SOCIOS_27,
  SOCIOS_28,
  SOCIOS_29,
  SOCIOS_30,
  SOCIOS_31,
  SOCIOS_32,
  SOCIOS_33,
  SOCIOS_34,
  SOCIOS_35,
  SOCIOS_36,
  SOCIOS_37,
  SOCIOS_38,
  SOCIOS_39,
  SOCIOS_40,
  SOCIOS_41,
  SOCIOS_42,
  SOCIOS_43,
  SOCIOS_44,
  SOCIOS_45,
  SOCIOS_46,
  SOCIOS_47,
  SOCIOS_48,
} = STAFF

const useStyle = makeStyles(() => ({
  blueLetters: {
    color: '#4BC0C0',
    fontWeight: 'bold',
  },
}))

const renderCellBuilder = classes => {
  const renderCell = variable => {
    const styles = []
    if (variable !== 0) {
      styles.push(classes.blueLetters)
    }
    return <Typography className={styles}>{variable}</Typography>
  }

  return renderCell
}

const returnData = (variables, classes) => {
  const renderCell = renderCellBuilder(classes)
  return [
    [
      'First Quarter',
      renderCell(variables[SOCIOS_1]),
      renderCell(variables[SOCIOS_5]),
      renderCell(variables[SOCIOS_9]),
      renderCell(variables[SOCIOS_13]),
      renderCell(variables[SOCIOS_17]),
      renderCell(variables[SOCIOS_21]),
      renderCell(variables[SOCIOS_25]),
      renderCell(variables[SOCIOS_29]),
      renderCell(variables[SOCIOS_33]),
      renderCell(variables[SOCIOS_37]),
      renderCell(variables[SOCIOS_41]),
      renderCell(variables[SOCIOS_45]),
    ],
    [
      'Second Quarter',
      renderCell(variables[SOCIOS_2]),
      renderCell(variables[SOCIOS_6]),
      renderCell(variables[SOCIOS_10]),
      renderCell(variables[SOCIOS_14]),
      renderCell(variables[SOCIOS_18]),
      renderCell(variables[SOCIOS_22]),
      renderCell(variables[SOCIOS_26]),
      renderCell(variables[SOCIOS_30]),
      renderCell(variables[SOCIOS_34]),
      renderCell(variables[SOCIOS_38]),
      renderCell(variables[SOCIOS_42]),
      renderCell(variables[SOCIOS_46]),
    ],
    [
      'Third Quarter',
      renderCell(variables[SOCIOS_3]),
      renderCell(variables[SOCIOS_7]),
      renderCell(variables[SOCIOS_11]),
      renderCell(variables[SOCIOS_15]),
      renderCell(variables[SOCIOS_19]),
      renderCell(variables[SOCIOS_23]),
      renderCell(variables[SOCIOS_27]),
      renderCell(variables[SOCIOS_31]),
      renderCell(variables[SOCIOS_35]),
      renderCell(variables[SOCIOS_39]),
      renderCell(variables[SOCIOS_43]),
      renderCell(variables[SOCIOS_47]),
    ],
    [
      'Fourth Quarter',
      renderCell(variables[SOCIOS_4]),
      renderCell(variables[SOCIOS_8]),
      renderCell(variables[SOCIOS_12]),
      renderCell(variables[SOCIOS_16]),
      renderCell(variables[SOCIOS_20]),
      renderCell(variables[SOCIOS_24]),
      renderCell(variables[SOCIOS_28]),
      renderCell(variables[SOCIOS_32]),
      renderCell(variables[SOCIOS_36]),
      renderCell(variables[SOCIOS_40]),
      renderCell(variables[SOCIOS_44]),
      renderCell(variables[SOCIOS_48]),
    ],
  ]
}

const columns = () => [
  { type: 'label', name: 'Quarter' },
  { type: 'label', name: 'Year 1' },
  { type: 'label', name: 'Year 2' },
  { type: 'label', name: 'Year 3' },
  { type: 'label', name: 'Year 4' },
  { type: 'label', name: 'Year 5' },
  { type: 'label', name: 'Year 6' },
  { type: 'label', name: 'Year 7' },
  { type: 'label', name: 'Year 8' },
  { type: 'label', name: 'Year 9' },
  { type: 'label', name: 'Year 10' },
  { type: 'label', name: 'Year 11' },
  { type: 'label', name: 'Year 12' },
]

const PartnersTable = () => {
  const variablesOneToFourYears = useSelector(
    state => state.staff.variablesPartnersFromOneToFourYears,
  )
  const variablesFiveToEightYears = useSelector(
    state => state.staff.variablesPartnersFromSixToEightYears,
  )
  const variablesNineToTwelveYears = useSelector(
    state => state.staff.variablesPartnersFromNineToTwelveYears,
  )

  const variables = {
    ...variablesOneToFourYears,
    ...variablesFiveToEightYears,
    ...variablesNineToTwelveYears,
  }
  const classes = useStyle()

  return (
    <Table
      titleColor="#CF7070"
      titleTable="Partner Tenure Table"
      columns={columns()}
      data={returnData(variables, classes)}
    />
  )
}
export default PartnersTable
