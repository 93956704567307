import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { FINANCES } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const {
  CAJA,
  CUENTAS_POR_COBRAR_SEGMENTO_FINO,
  CUENTAS_POR_COBRAR_SEGMENTO_PURETE,
  PRESTAMO,
} = FINANCES

const {
  CASH,
  ACCOUNT_RECEIVABLE_CORPORATE_SEGMENT,
  ACCOUNT_RECEIVABLE_POPULAR_SEGMENT,
  LOAN,
} = CONSTANTS

const classLabel = {
  fontSize: '.875rem',
}

const renderElement = variable => {
  return <Typography style={classLabel}>{`$${variable}`}</Typography>
}

const returnElements = variables => [
  {
    id: '1-Balances',
    label: CASH,
    component: renderElement(variables[CAJA]),
  },
  {
    id: '2-Balances',
    label: ACCOUNT_RECEIVABLE_CORPORATE_SEGMENT,
    component: renderElement(variables[CUENTAS_POR_COBRAR_SEGMENTO_FINO]),
  },
  {
    id: '3-Balances',
    label: ACCOUNT_RECEIVABLE_POPULAR_SEGMENT,
    component: renderElement(variables[CUENTAS_POR_COBRAR_SEGMENTO_PURETE]),
  },
  {
    id: '4-Balances',
    label: LOAN,
    component: renderElement(variables[PRESTAMO]),
  },
]
const Balances = () => {
  const variables = useSelector(state => state.finances.variablesBalances)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default Balances
