import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { MARKET, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_FINO } = MARKET
const { TIME } = HEADER
const { TRIALS } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: TRIALS,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_FINO],
      },
    ],
  }
}

const PremiumMarketTrials = () => {
  const variables = useSelector(state => state.market.variablesMarketCharts)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleColor="#758C5E" titleChart={TRIALS} />
  ) : null
}
export default PremiumMarketTrials
