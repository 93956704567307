import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { EXPERIENCE, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const {
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_ASISTENTES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_TRABAJADORES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_SOCIOS_PARA_TRABAJAR,
} = EXPERIENCE
const { TIME } = HEADER
const { ASSISTANS, DIRECTORS, PARTNERS } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: ASSISTANS,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_ASISTENTES_PARA_TRABAJAR],
      },
      {
        label: DIRECTORS,
        fill: false,
        borderColor: '#FFCE56',
        data: variables[HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_TRABAJADORES_PARA_TRABAJAR],
      },
      {
        label: PARTNERS,
        fill: false,
        borderColor: '#FF6384',
        data: variables[HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_SOCIOS_PARA_TRABAJAR],
      },
    ],
  }
}

const EfectiveTimeAvailable = () => {
  const variables = useSelector(
    state => state.experience.currentValues.variablesEfectiveTimeAvaliable,
  )
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart="Efective Time Avaliable" />
  ) : null
}

export default EfectiveTimeAvailable
