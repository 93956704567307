import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { compose } from 'redux'
import { history } from 'store/configureStore'
import * as ROUTES from '../../constants/routes'
import { getCurrentSession } from '../../services/authService'
import { saveOnLocalStorage, localStorageKeys } from '../../services/localstorage'
import Loading from '../../components/Loading'

class CreateTeamPreamble extends Component {
  async componentDidMount() {
    const { location } = this.props
    const query = new URLSearchParams(location.search)
    let simulationId
    for (const param of query.entries()) {
      const [variable, value] = param
      if (variable === 'simulationId') {
        simulationId = value
      }
    }

    if (!simulationId) {
      history.replace(ROUTES.CHECK_AUTH)
      return
    }

    saveOnLocalStorage(localStorageKeys.CREATE_TEAM_SIMULATION_ID, simulationId)

    const session = await getCurrentSession()
    if (session) {
      history.replace(ROUTES.CREATE_TEAM)
    }
  }

  render() {
    return (
      <>
        <Loading />
      </>
    )
  }
}

const HOC = compose(withRouter)

export default HOC(CreateTeamPreamble)
