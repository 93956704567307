import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import InputNumber from 'components/InputNumber'
import { changeVariable } from 'actions/capacityActions'
import { CONSTANTS } from 'constants/constants'
import { CAPACITY } from 'constants/forioVariables'
import List from 'components/List'

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE,
} = CAPACITY

const { CAPACITY_DISTRIBUTION_PER_SEGMENT, POPULAR_SEGMENT, CORPORATE_SEGMENT } = CONSTANTS

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = (variables, changeValue) => [
  {
    id: '2-CapacityPerDistributionPerSegment',
    label: POPULAR_SEGMENT,
    component: (
      <Typography style={classLabel}>
        {variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE].toFixed(2)}
      </Typography>
    ),
  },
  {
    id: '1-CapacityPerDistributionPerSegment',
    label: CORPORATE_SEGMENT,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO]}
        id={DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO}
        change={event => changeValue(event)}
        max={1}
        min={0}
      />
    ),
  },
]

const CapacityDistributionPerSegment = () => {
  const dispatch = useDispatch()

  const changeValue = ({ value }) => {
    const valueCorporateSegment = parseFloat(value, 10)
    const valuePupularSegment = 1 - parseFloat(value, 10)

    const payloadPupularSegment = {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE,
      value: valuePupularSegment,
      variable: 'variablesCapacityDistributionPerSegment',
    }
    dispatch(changeVariable(payloadPupularSegment))

    const payloadCorporateSegment = {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
      value: valueCorporateSegment,
      variable: 'variablesCapacityDistributionPerSegment',
    }
    dispatch(changeVariable(payloadCorporateSegment))
  }

  const variables = useSelector(state => state.capacity.variablesCapacityDistributionPerSegment)

  return (
    <Box display="flex" alignItems="center">
      <List
        items={returnElements(variables, changeValue)}
        listTitle={CAPACITY_DISTRIBUTION_PER_SEGMENT}
      />
    </Box>
  )
}

export default CapacityDistributionPerSegment
