import React from 'react'
import { Line } from 'react-chartjs-2'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'

const options = {}

const useStyles = makeStyles({
  card: {
    backgroundColor: '#3A4149',
    border: '1px solid #23282c',
    boxShadow: 'none',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '1px solid #23282c',
    padding: '.75rem 1.25rem',
    backgroundColor: prop => prop.titleColor || '#119E9E',
  },
  title: {
    color: '#e4e7ea',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
})

const LineChart = ({ data, titleChart, titleColor }) => {
  const classes = useStyles({ titleColor })
  const newData = {
    ...data,
    datasets: data.datasets.map(item => ({
      ...item,
      pointRadius: 0,
    })),
  }
  return (
    <Card className={classes.card}>
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{titleChart}</h2>
      </div>
      <CardContent>
        <Line data={newData} options={options} />
      </CardContent>
    </Card>
  )
}
export default LineChart
