import {
  GET_VARIBLES_EFECTIVE_TIME_AVAILABLE,
  GET_VARIBLES_MEAN_ABILITY,
  GET_VARIBLES_MEAN_EXPERIENCE,
  GET_VARIBLES_BURNOUT,
  CHANGE_EXPERIENCE_VARIABLE,
  GET_VARIABLE_MENTORSHIP,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { EXPERIENCE } from 'constants/forioVariables'

const {
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
  HABILIDAD_PROMEDIO_ASISTENTES,
  HABILIDAD_PROMEDIO_DE_DIRECTORES,
  SMOOTHING_DE_EXPERIENCIA_FORMULA_SENCILLA_EN_DIAS,
  EXPERIENCIA_DE_DIRECTORES,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_ASISTENTES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_TRABAJADORES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_SOCIOS_PARA_TRABAJAR,
  FACTOR_DE_BURNOUT_ASISTENTES_SMOOTHED,
  FACTOR_DE_BURNOUT_DIRECTORES_SMOOTHED,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_DIRECTORES,
} = EXPERIENCE

const variablesMeanAbility = [HABILIDAD_PROMEDIO_ASISTENTES, HABILIDAD_PROMEDIO_DE_DIRECTORES]

const getVariablesMeanAbility = variables => ({
  type: GET_VARIBLES_MEAN_ABILITY,
  variables,
})

const fetchVariablesMeanAbility = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesMeanAbility)
    dispatch(getVariablesMeanAbility(variables))
  }
}

const variablesMeanExperience = [
  SMOOTHING_DE_EXPERIENCIA_FORMULA_SENCILLA_EN_DIAS,
  EXPERIENCIA_DE_DIRECTORES,
]

const getVariablesMeanExperience = variables => ({
  type: GET_VARIBLES_MEAN_EXPERIENCE,
  variables,
})

const fetchVariablesMeanExperience = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesMeanExperience)
    dispatch(getVariablesMeanExperience(variables))
  }
}

const variablesEfectiveTimeAvaliable = [
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_ASISTENTES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_TRABAJADORES_PARA_TRABAJAR,
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_SOCIOS_PARA_TRABAJAR,
]

const getVariablesEfectiveTimeAvaliable = variables => ({
  type: GET_VARIBLES_EFECTIVE_TIME_AVAILABLE,
  variables,
})

const fetchVariablesEfectiveTimeAvaliable = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesEfectiveTimeAvaliable)
    dispatch(getVariablesEfectiveTimeAvaliable(variables))
  }
}

const variablesBurnout = [
  FACTOR_DE_BURNOUT_ASISTENTES_SMOOTHED,
  FACTOR_DE_BURNOUT_DIRECTORES_SMOOTHED,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_DIRECTORES,
]

const getVariablesBurnout = variables => ({
  type: GET_VARIBLES_BURNOUT,
  variables,
})

const fetchVariablesBurnout = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesBurnout)
    dispatch(getVariablesBurnout(variables))
  }
}

const changeVariable = payload => {
  return { type: CHANGE_EXPERIENCE_VARIABLE, payload }
}

const variablesFractionOfTimeUsedInMentorship = [
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
]

const getVariablesFractionOfTimeUsedInMentorship = variables => ({
  type: GET_VARIABLE_MENTORSHIP,
  variables,
})

const fetchVariablesFractionOfTimeUsedInMentorship = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesFractionOfTimeUsedInMentorship)
    dispatch(getVariablesFractionOfTimeUsedInMentorship(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFuntionsFetchExperience = [
  fetchVariablesBurnout,
  fetchVariablesEfectiveTimeAvaliable,
  fetchVariablesMeanAbility,
  fetchVariablesMeanExperience,
  fetchVariablesFractionOfTimeUsedInMentorship,
]

export {
  fetchVariablesMeanAbility,
  fetchVariablesMeanExperience,
  fetchVariablesEfectiveTimeAvaliable,
  fetchVariablesFractionOfTimeUsedInMentorship,
  fetchVariablesBurnout,
  changeVariable,
  allFuntionsFetchExperience,
  clearNewValues,
}
