const MARKETING = {
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO: 'DecisionPrecioPorHoraIYFRegionIFino',
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO: 'DecisionPrecioPorHoraBRRegionIFino',
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO: 'DecisionPrecioPorHoraJuiciosRegionIFino',
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO: 'DecisionPrecioPorHoraLYGRegionIFino',
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE: 'DecisionPrecioPorHoraJuiciosrIPurete',
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE: 'DecisionPrecioPorHoraIYFrIPurete',
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE: 'DecisionPrecioPorHoraBRrIPurete',
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE: 'DecisionPrecioPorHoraLYGrIPurete',
  SALE_VENTAS_EN_DOLLARS_IYF_PURETE_ACC: 'SaleVentasEnDollarsIyFPureteAcc',
  SALE_VENTAS_EN_DOLLARS_IYFF_FINO_ACC: 'SaleVentasEnDollarsIyFFinoAcc',
  SALE_VENTAS_EN_DOLLARS_LYG_PURETE_ACC: 'SaleVentasEnDollarsLyGPureteAcc',
  SALE_VENTAS_EN_DOLLARS_LYG_FINO_ACC: 'SaleVentasEnDollarsLyGFinoAcc',
  SALE_VENTAS_EN_DOLLARS_JUICIOS_PURETE_ACC: 'SaleVentasEnDollarsJuiciosPureteAcc',
  SALE_VENTAS_EN_DOLLARS_JUICIOS_FINO_ACC: 'SaleVentasEnDollarsJuiciosFinoAcc',
  SALE_VENTAS_EN_DOLLARS_BR_PURETE_ACC: 'SaleVentasEnDollarsBRPureteAcc',
  SALE_VENTAS_EN_DOLLARS_BR_FINO_ACC: 'SaleVentasEnDollarsBRFinoAcc',
  SUCURSALES_REGION_I: 'SucursalesRegionI',
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I: 'DecisionDeAperturaDeSucursalRegion1',
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I: 'DecisionDeCierreDeSucursalRegionI',
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I:
    'DecisionDeInversionEnMercadeoComoPorcentajeDeLosIngresosRegionI',
  DECISION_DE_DIAS_DE_CREDITO_REGION_I: 'DecisionDeDiasDeCreditoRegionI',
  MARKET_SHARE_PURETE_SMOOTHED: 'MarketSharePureteSmoothed',
  MARKET_SHARE_FINO_SMOOTHED: 'MarketShareFinoSmoothed',
}

const CAPACITY = {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAlSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_PURETE:
    'DecisionDePorcentajeDeCapacidadAsignadaAlSegmentoPurete',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionISegmentoPureteAsistentes',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionISegmentoPureteDirectores',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionISegmentoPureteSocios',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionISegmentoPureteAsistentes',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionISegmentoPureteDirectores',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionISegmentoPureteSocios',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionISegmentoPureteAsistentes',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionISegmentoPureteDirectores',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionISegmentoPureteSocios',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionISegmentoPureteAsistentes',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionISegmentoPureteDirectores',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionISegmentoPureteSocios',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionIAsistentesSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionIDirectoresSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAIYFRegionISociosSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionIAsistentesSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionIDirectoresSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaALyGRegionISociosSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionIAsistentesSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionIDirectoresSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaAJuiciosRegionISociosSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionIAsistentesSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionIDirectoresSegmentoFino',
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO:
    'DecisionDePorcentajeDeCapacidadAsignadaABRRegionISociosSegmentoFino',
  COLA_DE_HORAS_IYF_REGION_I_PURETE_ASISTENTE: 'ColaDeHorasIyFRegionIPureteAsistente',
  COLA_DE_HORAS_IYF_REGION_I_PURETE_DIRECTOR: 'ColaDeHorasIyFRegionIPureteDirector',
  COLA_DE_HORAS_IYF_REGION_I_PURETE_SOCIO: 'ColaDeHorasIyFRegionIPureteSocio',
  COLA_DE_HORAS_LYG_REGION_I_PURETE_ASISTENTE: 'ColaDeHorasLyGRegionIPureteAsistente',
  COLA_DE_HORAS_LYG_REGION_I_PURETE_DIRECTOR: 'ColaDeHorasLyGRegionIPureteDirector',
  COLA_DE_HORAS_LYG_REGION_I_PURETE_SOCIO: 'ColaDeHorasLyGRegionIPureteSocio',
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_ASISTENTE: 'ColaDeHorasJuiciosRegionIPureteAsistente',
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_DIRECTOR: 'ColaDeHorasJuiciosRegionIPureteDirector',
  COLA_DE_HORAS_JUCIOS_REGION_I_PURETE_SOCIO: 'ColaDeHorasJuiciosRegionIPureteSocio',
  COLA_DE_HORAS_BRR_REGION_I_PURETE_ASISTENTE: 'ColaDeHorasBRRegionIPureteAsistente',
  COLA_DE_HORAS_BRR_REGION_I_PURETE_DIRECTOR: 'ColaDeHorasBRRegionIPureteDirector',
  COLA_DE_HORAS_BRR_REGION_I_PURETE_SOCIO: 'ColaDeHorasBRRegionIPureteSocio',
  COLA_DE_HORAS_IYF_REGION_I_ASISTENTE_FINO: 'ColaDeHorasIyFRegionIAsistenteFINO',
  COLA_DE_HORAS_IYF_REGION_I_FINO_DIRECTORES: 'ColaDeHorasIyFRegionIFINODIRECTORES',
  COLA_DE_HORAS_IYF_REGION_I_FINO_SOCIOS: 'ColaDeHorasIyFRegionIFINOSOCIOS',
  COLA_DE_HORAS_LYG_REGION_I_ASISTENTE_FINO: 'ColaDeHorasLyGRegionIAsistenteFINO',
  COLA_DE_HORAS_LYG_REGION_I_FINO_DIRECTORES: 'ColaDeHorasLyGRegionIFINODIRECTORES',
  COLA_DE_HORAS_LYG_REGION_I_FINO_SOCIOS: 'ColaDeHorasLyGRegionIFINOSOCIOS',
  COLA_DE_HORAS_JUICIOS_REGION_I_ASISTENTE_FINO: 'ColaDeHorasJuiciosRegionIAsistenteFINO',
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_DIRECTORES: 'ColaDeHorasJuiciosRegionIFINODIRECTORES',
  COLA_DE_HORAS_JUICIOS_REGION_I_FINO_SOCIOS: 'ColaDeHorasJuiciosRegionIFINOSOCIOS',
  COLA_DE_HORAS_BRR_REGION_I_ASISTENTE_FINO: 'ColaDeHorasBRRegionIAsistenteFINO',
  COLA_DE_HORAS_BRR_REGION_I_FINO_DIRECTORES: 'ColaDeHorasBRRegionIFINODIRECTORES',
  COLA_DE_HORAS_BRR_REGION_I_FINO_SOCIOS: 'ColaDeHorasBRRegionIFINOSOCIOS',
  CUENTA_SEGMENTO_PURETE_EN_ESPERA_DE_FACTURACION: 'CuentasSegmentoPureteEnEsperaDeFacturacion',
  CUENTA_SEGMENTO_FINO_EN_ESPERA_DE_FACTURACION: 'CuentasSegmentoFinoEnEsperaDeFacturacion',
}

const EXPERIENCE = {
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR:
    'DecisionDePorcentajeDeTiempoDeLosDirectoresDedicadosASupervision',
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA:
    'DecisionDePorcentajeDeTiempoDeLosSociosDedicadoALaMentoria',
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES:
    'DecisionDePorcentajeDeTiempoDeLosSociosDedicadoAConseguirClientes',
  HABILIDAD_PROMEDIO_ASISTENTES: 'HabilidadPromedioAsistentes',
  HABILIDAD_PROMEDIO_DE_DIRECTORES: 'HabilidadPromedioDeDirectores',
  SMOOTHING_DE_EXPERIENCIA_FORMULA_SENCILLA_EN_DIAS: 'SmoothingDeExperienciaFormulaSencillaEnDias',
  EXPERIENCIA_DE_DIRECTORES: 'ExperienciaDeDirectores',
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_ASISTENTES_PARA_TRABAJAR:
    'HorasTotalesEfectivasDisponiblesDeAsistentesParaTrabajar',
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_TRABAJADORES_PARA_TRABAJAR:
    'HorasTotalesEfectivasDisponiblesDeDirectoresParaTrabajar',
  HORAS_TOTALES_EFECTIVAS_DISPONIBLES_DE_SOCIOS_PARA_TRABAJAR:
    'HorasTotalesEfectivasDisponiblesDeSociosParaTrabajar',
  FACTOR_DE_BURNOUT_ASISTENTES_SMOOTHED: 'FactorDeBurnoutAsistentesSmoothed',
  FACTOR_DE_BURNOUT_DIRECTORES_SMOOTHED: 'FactorDeBurnoutDirectoresSmoothed',
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES: 'DecisionHorasLaboralesExigidasAAsistentes',
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_DIRECTORES: 'DecisionHorasLaboralesExigidasADirectores',
}

const STAFFING = {
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE: 'DecisionDeContratacionDelTrimestre',
  DECISIONES_DE_CONTRATACION_LATERAL: 'DecisionDeContratacionLateral',
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO: 'DecisionDeStaffDeSoporteNovatoDeseado',
  DECISION_DE_PROMOCION_DE_ABS: 'DecisionDePromocionDeAbs',
  DECISION_DE_PROMOCION_A_SOCIOS: 'DecisionDePromocionASocios',
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4: 'DecisionDeDespidoDeAsistentesA4',
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8: 'DecisionDeDespidoDeAsistentesA8',
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4: 'DecisionDeDespidoDeDirectoresD4',
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8: 'DecisionDeDespidoDeDirectoresD8',
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12: 'DecisionDeDespidoDeDirectoresD12',
}

const QUALITY = {
  REPUTACION_PERCIBIDA: 'ReputacionPercibida',
  CALIDAD_PERCIBIDA: 'CalidadPercibida',
  RAZON_CLIENTES_A_ABOGADOS_TOTALES: 'RazonClientesAAbogadosTotalesSmoothed',
}

const FINANCES = {
  VENTAS: 'Ventas',
  COMISIONES: 'Comisiones',
  INGRESOS_TOTALES_POR_VENTAS_Y_COMISIONES: 'IngresosTotalesPorVentasYComisiones',
  SALARIOS: 'Salarios',
  BONOS_Y_OTROS: 'BonosYOtros',
  SEGUROS: 'Seguros',
  MERCADEO: 'Mercadeo',
  LIBROS_Y_MATERIALES: 'LibrosYMateriales',
  OTROS_GASTOS_Y_DEPRECIACIONES: 'OtrosGastosYDepreciaciones',
  MALPRAXIS: 'Malpraxis',
  COMUNICACIONES: 'Comunicaciones',
  UTILIDAD_OPERATIVA: 'UtilidadOperativa',
  INTERESES_PAGADOS: 'InteresesPagados',
  UAI: 'UAI',
  IMPUESTO_DE_LA_RENTA: 'ImpuestoDeLaRenta',
  CAJA: 'Caja',
  CUENTAS_POR_COBRAR_SEGMENTO_FINO: 'CuentasPorCobrarSegmentoFino',
  CUENTAS_POR_COBRAR_SEGMENTO_PURETE: 'CuentasPorCobrarSegmentoPurete',
  PRESTAMO: 'Prestamo',
}

const SALARIES = {
  DECISION_DE_SALARIOS_ABS: 'DecisionDeSalariosAbs',
  DECISION_DE_SALARIOS_DIRECTORES: 'DecisionDeSalariosDirectores',
  DECISION_DE_SALARIOS_SOCIOS: 'DecisionDeSalariosSocios',
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE: 'DecisionDeSalariosPromedioStaffDeSoporte',
  DECISION_BONO_DE_CONTRARACION_ABS: 'DecisionBonoDeContratacionAbs',
  DECISION_BONO_DE_CONTRARACION_LATERAL: 'DecisionDeBonoDeContratacionLateral',
  BONO_DE_CONTRATACION_ABS_EN_LA_ECONOMIA_SMOOTHED: 'BonosDeContratacionAbsEnLaEconomiaSmoothed',
  BONO_DE_CONTRATACION_LATERAL_EN_LA_ECONOMIA_SMOOTHED:
    'BonosDeContratacionLateralEnLaEconomiaSmoothed',
  SALARIOS_ASISTENTES_EN_LA_ECONOMIA_SMOOTHED: 'SalariosAsistentesEnLaEconomiaSmoothed',
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_STAFF: 'SalariosEnLaEconomiaSmoothedSTAFF',
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_DIRECTORES: 'SalariosEnLaEconomiasmoothedDIRECTORES',
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_SOCIOS: 'SalariosEnLaEconomiasmoothedSOCIOS',
  SEGMENTO_FINO_REGION_I: 'SegmentoFinoRegionI',
  SEGMENTO_PURETE_REGION_I: 'SegmentoPureteRegionI',
}
const STAFF = {
  SOCIOS_1: 'Socios1',
  SOCIOS_2: 'Socios2',
  SOCIOS_3: 'Socios3',
  SOCIOS_4: 'Socios4',
  SOCIOS_5: 'Socios5',
  SOCIOS_6: 'Socios6',
  SOCIOS_7: 'Socios7',
  SOCIOS_8: 'Socios8',
  SOCIOS_9: 'Socios9',
  SOCIOS_10: 'Socios10',
  SOCIOS_11: 'Socios11',
  SOCIOS_12: 'Socios12',
  SOCIOS_13: 'Socios13',
  SOCIOS_14: 'Socios14',
  SOCIOS_15: 'Socios15',
  SOCIOS_16: 'Socios16',
  SOCIOS_17: 'Socios17',
  SOCIOS_18: 'Socios18',
  SOCIOS_19: 'Socios19',
  SOCIOS_20: 'Socios20',
  SOCIOS_21: 'Socios21',
  SOCIOS_22: 'Socios22',
  SOCIOS_23: 'Socios23',
  SOCIOS_24: 'Socios24',
  SOCIOS_25: 'Socios25',
  SOCIOS_26: 'Socios26',
  SOCIOS_27: 'Socios27',
  SOCIOS_28: 'Socios28',
  SOCIOS_29: 'Socios29',
  SOCIOS_30: 'Socios30',
  SOCIOS_31: 'Socios31',
  SOCIOS_32: 'Socios32',
  SOCIOS_33: 'Socios33',
  SOCIOS_34: 'Socios34',
  SOCIOS_35: 'Socios35',
  SOCIOS_36: 'Socios36',
  SOCIOS_37: 'Socios37',
  SOCIOS_38: 'Socios38',
  SOCIOS_39: 'Socios39',
  SOCIOS_40: 'Socios40',
  SOCIOS_41: 'Socios41',
  SOCIOS_42: 'Socios42',
  SOCIOS_43: 'Socios43',
  SOCIOS_44: 'Socios44',
  SOCIOS_45: 'Socios45',
  SOCIOS_46: 'Socios46',
  SOCIOS_47: 'Socios47',
  SOCIOS_48: 'Socios48',
  TOTAL_DE_SOCIOS: 'TotalDeSocios',
  RENUNCIAS_SOCIOS: 'RenunciasSocios',
  DIRECTORS_1: 'Directores1',
  DIRECTORS_2: 'Directores2',
  DIRECTORS_3: 'Directores3',
  DIRECTORS_4: 'Directores4',
  DIRECTORS_5: 'Directores5',
  DIRECTORS_6: 'Directores6',
  DIRECTORS_7: 'Directores7',
  DIRECTORS_8: 'Directores8',
  DIRECTORS_9: 'Directores9',
  DIRECTORS_10: 'Directores10',
  DIRECTORS_11: 'Directores11',
  DIRECTORS_12: 'Directores12',
  DIRECTORS_13: 'Directores13',
  DIRECTORS_14: 'Directores14',
  DIRECTORS_15: 'Directores15',
  DIRECTORS_16: 'Directores16',
  PROMOVIDOS_A_SOCIOS: 'PromovidosASocios',
  RENUNCIAS_DIRECTORES: 'RenunciasDirectores',
  DESPIDOS_DIECTORES: 'DespidosDirectores',
  TOTAL_DE_DIRECTORES: 'TotalDeDirectores',
  AB: 'ab',
  AB_1: 'Ab1',
  AB_2: 'Ab2',
  AB_3: 'Ab3',
  AB_4: 'Ab4',
  AB_5: 'Ab5',
  AB_6: 'Ab6',
  AB_7: 'Ab7',
  AB_8: 'Ab8',
  AB_9: 'Ab9',
  AB_10: 'Ab10',
  AB_11: 'Ab11',
  PROMOVIDOS_A_DIRECTORES: 'PromovidosADirectores',
  RENUNCIAS_ASISTENTES: 'RenunciasAsistentes',
  DESPIDOS_ASISTENTES: 'DespidosAsistentes',
  TOTAL_DE_ASISTENTES: 'TotalDeAsistentes',
  STAFF_DE_SOPORTE_NOVATO: 'StaffDeSoporteNovato',
  STAFF_DE_SOPORTE_EXPERIMENTADO: 'StaffDeSoporteExperimentado',
}

const HEADER = {
  TIME: 'Time',
}

const MARKET = {
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_PURETE: 'PercepcionPrecioEconomiaIyFRegionIPurete',
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_FINO: 'PercepcionPrecioEconomiaIyFRegionIFino',
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_PURETE: 'PercepcionPrecioEconomiaLyGRegionIPurete',
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_FINO: 'PercepcionPrecioEconomiaLyGRegionIFino',
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_PURETE: 'PercepcionPrecioEconomiaJuiciosRegionIPurete',
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_FINO: 'PercepcionPrecioEconomiaJuiciosRegionIFino',
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_PURETE: 'PercepcionPrecioEconomiaBRRegionIPurete',
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_FINO: 'PercepcionPrecioEconomiaBRRegionIFino',
  SUCURSALES_DE_LA_COMPETENCIA: 'SucursalesDeLaCompetencia',
}

export {
  MARKETING,
  CAPACITY,
  HEADER,
  EXPERIENCE,
  STAFFING,
  QUALITY,
  FINANCES,
  MARKET,
  STAFF,
  SALARIES,
}
