import {
  CLEAR_VALUES,
  GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS,
  GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS,
  GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS,
  GET_VARIABLES_PARTNERS_LIST,
  GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS,
  GET_VARIABLES_DIRECTORS_LIST,
  GET_VARIABLES_AB,
  GET_VARIABLES_LIST_AB,
} from 'constants/actionTypes'

const initialStaff = {
  variablesPartnersFromOneToFourYears: {},
  variablesPartnersFromSixToEightYears: {},
  variablesPartnersFromNineToTwelveYears: {},
  variablesPartnersList: {},
  variablesDirectorsFromOneToFourYears: {},
  variablesDirectorsList: {},
  variablesAb: {},
  variablesAbList: {},
}

const Staff = (state = initialStaff, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialStaff
    case GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS:
      return {
        ...state,
        variablesPartnersFromOneToFourYears: action.variables,
      }
    case GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS:
      return {
        ...state,
        variablesPartnersFromSixToEightYears: action.variables,
      }
    case GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS:
      return {
        ...state,
        variablesPartnersFromNineToTwelveYears: action.variables,
      }
    case GET_VARIABLES_PARTNERS_LIST:
      return {
        ...state,
        variablesPartnersList: action.variables,
      }
    case GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS:
      return {
        ...state,
        variablesDirectorsFromOneToFourYears: action.variables,
      }
    case GET_VARIABLES_DIRECTORS_LIST:
      return {
        ...state,
        variablesDirectorsList: action.variables,
      }
    case GET_VARIABLES_AB:
      return {
        ...state,
        variablesAb: action.variables,
      }
    case GET_VARIABLES_LIST_AB:
      return {
        ...state,
        variablesAbList: action.variables,
      }
    default:
      return state
  }
}

export default Staff
