import React, { useContext } from 'react'
import clsx from 'clsx'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton'
import { MyContext } from 'common/ConfigProvider'
import { loadLocalStorageValue, localStorageKeys } from 'services/localstorage'
import ButtonUpdateSimulation from 'components/ButtonUpdateSimulation'
import LabelTimeSimulation from 'components/LabelTimeSimulation'
import ButtonRefreshSimulation from 'components/ButtonRefreshSimulation'

import style from './style'

// const { TIME } = HEADER

const Header = () => {
  const stateMenu = useContext(MyContext)
  const classes = style()
  const { open, setOpen } = stateMenu
  const handleDrawerOpen = () => {
    setOpen()
  }

  const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)

  return (
    <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
      <Toolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="Open drawer"
          onClick={handleDrawerOpen}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
          className={classes.title}
        >{`Team: ${teamInformation.name}`}</Typography>
        <ButtonUpdateSimulation />
        <ButtonRefreshSimulation />
        <LabelTimeSimulation />
      </Toolbar>
    </AppBar>
  )
}

export default Header
