import { isEmpty } from 'lodash'

const validateStates = state => {
  const statusLoading = []
  for (const key in state) {
    if (state) {
      statusLoading.push(isEmpty(state[`${key}`]))
    }
  }

  const quantityLoadingTrue = statusLoading.filter(item => item === true)
  const isLoading = !isEmpty(quantityLoadingTrue)
  return isLoading
}

const getVariablesOfTheState = state => {
  let variables = {}
  for (const key in state) {
    if (state) {
      variables = {
        ...variables,
        ...state[`${key}`],
      }
    }
  }

  return variables
}

export { validateStates, getVariablesOfTheState }
