import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { STAFF } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const { PROMOTIONS_TO_ASSOCIATE, RESIGNATIONS, LAYOFFS, NUMBER_OF_DIRECTORS } = CONSTANTS

const { PROMOVIDOS_A_SOCIOS, RENUNCIAS_DIRECTORES, DESPIDOS_DIECTORES, TOTAL_DE_DIRECTORES } = STAFF

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-DirectorsList',
    label: PROMOTIONS_TO_ASSOCIATE,
    component: <Typography style={classLabel}>{variables[PROMOVIDOS_A_SOCIOS]}</Typography>,
  },
  {
    id: '2-DirectorsList',
    label: RESIGNATIONS,
    component: <Typography style={classLabel}>{variables[RENUNCIAS_DIRECTORES]}</Typography>,
  },
  {
    id: '3-DirectorsList',
    label: LAYOFFS,
    component: <Typography style={classLabel}>{variables[DESPIDOS_DIECTORES]}</Typography>,
  },
  {
    id: '4-DirectorsList',
    label: NUMBER_OF_DIRECTORS,
    component: <Typography style={classLabel}>{variables[TOTAL_DE_DIRECTORES]}</Typography>,
  },
]
const DirectorsList = () => {
  const variables = useSelector(state => state.staff.variablesDirectorsList)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default DirectorsList
