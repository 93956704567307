import React from 'react'
import { useSelector } from 'react-redux'

import { LineChart } from 'components/Charts'
import { QUALITY, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { REPUTACION_PERCIBIDA } = QUALITY
const { TIME } = HEADER
const { PERCEIVED_REPUTATION } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: PERCEIVED_REPUTATION,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[REPUTACION_PERCIBIDA],
      },
    ],
  }
}

const PerceivedReputation = () => {
  const variables = useSelector(state => state.quality.variablesChartPerceivedReputation)

  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart={PERCEIVED_REPUTATION} />
  ) : null
}
export default PerceivedReputation
