import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Provider } from 'react-redux'
import { Route, Router, Switch } from 'react-router-dom'
import ScrollToTop from 'components/ScrollToTop'
import { UserLayout } from 'layouts'
import '../styles/main.css'
import CallBack from 'routes/AuthRoutes/CallBack'
import CheckAuth from 'routes/AuthRoutes/CheckAuth'
import CreateTeamPreamble from 'routes/CreateTeamPreamble'
import CreateTeam from 'routes/CreateTeam'
import JoinTeamPreamble from 'routes/JoinTeamPreamble'
import JoinTeam from 'routes/JoinTeam'
import Simulations from 'routes/Simulations'
import * as ROUTES from 'constants/routes'

export default class Root extends Component {
  render() {
    const { store, history } = this.props
    return (
      <Provider store={store}>
        <Router history={history}>
          <ScrollToTop>
            <Switch>
              <Route path={ROUTES.BASE} component={UserLayout} />
              <div>
                <img
                  className="appBackground"
                  src="assets/images/background-two.png"
                  alt="Background"
                />
                <Route path={ROUTES.CALLBACK} component={CallBack} />
                <Route exact path={ROUTES.CHECK_AUTH} component={CheckAuth} />
                <Route path={ROUTES.CREATE_TEAM_PREAMBLE} component={CreateTeamPreamble} />
                <Route path={ROUTES.CREATE_TEAM} component={CreateTeam} />
                <Route path={ROUTES.JOIN_TEAM_PREAMBLE} component={JoinTeamPreamble} />
                <Route path={ROUTES.JOIN_TEAM} component={JoinTeam} />
                <Route path={ROUTES.SIMULATIONS} component={Simulations} />
              </div>
            </Switch>
          </ScrollToTop>
        </Router>
      </Provider>
    )
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}
