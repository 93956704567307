import { GET_VARIBLES_RESULTS, GET_VARIBLES_BALANCES, CLEAR_VALUES } from 'constants/actionTypes'

const initialExperience = {
  variablesResults: {},
  variablesBalances: {},
}

const Finances = (state = initialExperience, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialExperience
    case GET_VARIBLES_RESULTS:
      return {
        ...state,
        variablesResults: action.variables,
      }
    case GET_VARIBLES_BALANCES:
      return {
        ...state,
        variablesBalances: action.variables,
      }
    default:
      return state
  }
}

export default Finances
