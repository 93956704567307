import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeVariable } from 'actions/experienceActions'
import { EXPERIENCE } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { changeValue as onChangeValue } from 'common'
import Title from 'components/TitleTablesValidation'
import { validateVariables } from 'common/utils'
import { getVariablesOfTheState } from 'common/states'
import LabelValidation from 'components/LabelTitleValidation'

const {
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
} = EXPERIENCE

const {
  PARTHERS,
  DIRECTORS,
  FRACTION_OF_TIME_USED_IN_MENTORSHIP,
  FRACTION_OF_TIME_USED_TO_ACQUIRED_CLIENTS,
  EXPERIENCE_TEXT,
} = CONSTANTS

const columns = changeValue => [
  { type: 'label', name: '' },
  { type: 'inputNumber', name: FRACTION_OF_TIME_USED_IN_MENTORSHIP, onChange: changeValue },
  { type: 'inputNumber', name: FRACTION_OF_TIME_USED_TO_ACQUIRED_CLIENTS, onChange: changeValue },
]

const returnData = (variables, whichColumn) => [
  [
    <LabelValidation
      title={PARTHERS}
      whichColumn={whichColumn}
      columnValidate="experiencePartners"
    />,
    {
      id: DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
      value:
        variables[DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA],
    },

    {
      id: DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
      value:
        variables[
          DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES
        ],
    },
  ],
  [
    <LabelValidation
      title={DIRECTORS}
      whichColumn={whichColumn}
      columnValidate="experienceDirectors"
    />,
    {
      id: DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
      value: variables[DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR],
    },
  ],
]

const FiredDecision = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue(
    'variablesFractionOfTimeUsedInMentorship',
    dispatch,
    changeVariable,
  )
  const experience = useSelector(state => state.experience.currentValues)
  const variables = experience.variablesFractionOfTimeUsedInMentorship
  const experienceNewValues = useSelector(state => state.experience.newValues)
  const validationErrors = validateVariables(
    { ...getVariablesOfTheState(experienceNewValues) },
    EXPERIENCE_TEXT,
  )

  return (
    <Table
      columns={columns(changeValue)}
      data={returnData(variables, validationErrors.whichColumn)}
      titleTable={
        <Title
          title="Fraction of time used in client acquisition and mentorship"
          validationText="(Adding all values in a row should be less or equal to 1)"
          isError={
            validationErrors &&
            validationErrors.error &&
            (validationErrors.whichTable === EXPERIENCE_TEXT ||
              validationErrors.allTablesWithErrors === true)
          }
        />
      }
    />
  )
}

export default FiredDecision
