import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { Redirect, withRouter } from 'react-router-dom'
import { isLoggedIn } from '../../store/User/actions'
import LoadingComponent from '../../components/Loading'
import * as ROUTES from '../../constants/routes'

class CheckAuth extends Component {
  state = {
    loggedIn: false,
  }

  componentDidMount() {
    this.checkIsLoggedIn()
  }

  checkIsLoggedIn = async () => {
    const { userIsLoggedIn } = this.props
    const loggedIn = await userIsLoggedIn()
    this.setState({ loggedIn })
  }

  render() {
    const { loggedIn } = this.state

    if (loggedIn) {
      return <Redirect to={ROUTES.SIMULATIONS} />
    }
    return <LoadingComponent />
  }
}

const mapDispatchToProps = dispatch => ({
  userIsLoggedIn: () => dispatch(isLoggedIn()),
})

export default compose(
  connect(
    null,
    mapDispatchToProps,
  ),
)(withRouter(CheckAuth))
