import React from 'react'
import { useSelector } from 'react-redux'
import { STAFF } from 'constants/forioVariables'
import Table from 'components/Table'
import { makeStyles, Typography } from '@material-ui/core'

const { AB, AB_1, AB_2, AB_3, AB_4, AB_5, AB_6, AB_7, AB_8, AB_9, AB_10, AB_11 } = STAFF

const useStyle = makeStyles(() => ({
  blueLetters: {
    color: '#4BC0C0',
    fontWeight: 'bold',
  },
}))

const renderCellBuilder = classes => {
  const renderCell = variable => {
    const styles = []
    if (variable !== 0) {
      styles.push(classes.blueLetters)
    }
    return <Typography className={styles}>{variable}</Typography>
  }

  return renderCell
}

const returnData = (variables, classes) => {
  const renderCell = renderCellBuilder(classes)
  return [
    [
      'First Quarter',
      renderCell(variables[AB]),
      renderCell(variables[AB_4]),
      renderCell(variables[AB_8]),
    ],
    [
      'Second Quarter',
      renderCell(variables[AB_1]),
      renderCell(variables[AB_5]),
      renderCell(variables[AB_9]),
    ],
    [
      'Third Quarter',
      renderCell(variables[AB_2]),
      renderCell(variables[AB_6]),
      renderCell(variables[AB_10]),
    ],
    [
      'Fourth Quarter',
      renderCell(variables[AB_3]),
      renderCell(variables[AB_7]),
      renderCell(variables[AB_11]),
    ],
  ]
}

const columns = () => [
  { type: 'label', name: 'Quarter' },
  { type: 'label', name: 'Year 1' },
  { type: 'label', name: 'Year 2' },
  { type: 'label', name: 'Year 3' },
]

const AssistantsTenureTable = () => {
  const variables = useSelector(state => state.staff.variablesAb)
  const classes = useStyle()

  return (
    <Table
      titleColor="#9E8585"
      titleTable="Assistants Tenure Table"
      columns={columns()}
      data={returnData(variables, classes)}
    />
  )
}
export default AssistantsTenureTable
