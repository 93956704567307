import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { withStyles } from '@material-ui/core'
import Menu from '../../common/Menu'
import style from './style'

const CustomListItem = withStyles({
  root: {
    '&.Mui-selected': {
      backgroundColor: '#119E9E',
      '&:hover': { backgroundColor: '#119E9EDD' },
    },
  },
})(ListItem)

const MainListItems = () => {
  const classes = style()
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  function handleListItemClick(event, index) {
    setSelectedIndex(index)
  }
  return (
    <div>
      {Menu().map((item, index) => (
        <Link className={classes.linkItem} key={`${item.name}-link`} to={item.path}>
          <CustomListItem
            key={`${item.name}-item`}
            button
            selected={selectedIndex === index}
            onClick={event => handleListItemClick(event, index)}
          >
            <ListItemIcon className={classes.itemIcon}>
              <FontAwesomeIcon icon={item.icon} />
            </ListItemIcon>
            <ListItemText primary={item.name} />
          </CustomListItem>
        </Link>
      ))}
    </div>
  )
}

export default MainListItems
