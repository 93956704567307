import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { CAPACITY, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { CUENTA_SEGMENTO_FINO_EN_ESPERA_DE_FACTURACION } = CAPACITY
const { TIME } = HEADER
const { CORPORATE_SEGMENT } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: CORPORATE_SEGMENT,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[CUENTA_SEGMENTO_FINO_EN_ESPERA_DE_FACTURACION],
      },
    ],
  }
}

const AccountsAwaitingBillingInCorporateSegment = () => {
  const variables = useSelector(
    state => state.capacity.currentValues.variablesChartAccountsAwaitingBillingInCorporateSegment,
  )

  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart
      data={returnData(variables, time)}
      titleColor="#758C5E"
      titleChart="Accounts Awaiting Billing In Corporate Segment"
    />
  ) : null
}
export default AccountsAwaitingBillingInCorporateSegment
