import { makeStyles } from '@material-ui/core/styles'

const drawerWidth = 240

const style = makeStyles(theme => ({
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  toolbarText: {
    color: '#e4e7ea',
    fontWeight: 'bold',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
    padding: '0 10px',
    fontSize: '19px',
  },
  drawerPaper: {
    border: 'none',
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#3a4149',
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  anchorWithOutDecoration: {
    textDecoration: 'none',
    color: 'black',
  },
  linkItem: {
    color: 'white',
    textDecoration: 'none',
  },
  itemIcon: {
    color: '#bbb',
  },
  paddingNone: {
    padding: '0',
  },
}))

export default style
