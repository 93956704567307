import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { ClienttoLawyerRatio, PerceivedQuality, PerceivedReputation } from './Graphics'

const Quality = () => {
  const stateQuality = useSelector(state => state.quality)
  if (validateStates(stateQuality)) {
    return <Loading />
  }

  return (
    <div>
      <h2>Quality and Reputation</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <PerceivedReputation />
        </Box>
        <Box className="elementWrapper elementToRight">
          <PerceivedQuality />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <ClienttoLawyerRatio />
        </Box>
      </Box>
    </div>
  )
}

export default Quality
