import {
  GET_MARKET_VARIABLES,
  CLEAR_VALUES,
  GET_VARIABLE_MARKET_LABEL,
} from 'constants/actionTypes'

const initialMarket = {
  variablesMarketCharts: {},
  variablesMarketLabels: {},
}

const Market = (state = initialMarket, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialMarket
    case GET_MARKET_VARIABLES:
      return {
        ...state,
        variablesMarketCharts: action.variables,
      }
    case GET_VARIABLE_MARKET_LABEL:
      return {
        ...state,
        variablesMarketLabels: action.variables,
      }
    default:
      return state
  }
}

export default Market
