import {
  GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS,
  GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS,
  GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS,
  GET_VARIABLES_PARTNERS_LIST,
  GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS,
  GET_VARIABLES_DIRECTORS_LIST,
  GET_VARIABLES_AB,
  GET_VARIABLES_LIST_AB,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId } from 'services/forio/forio'
import { STAFF } from 'constants/forioVariables'

const {
  SOCIOS_1,
  SOCIOS_2,
  SOCIOS_3,
  SOCIOS_4,
  SOCIOS_5,
  SOCIOS_6,
  SOCIOS_7,
  SOCIOS_8,
  SOCIOS_9,
  SOCIOS_10,
  SOCIOS_11,
  SOCIOS_12,
  SOCIOS_13,
  SOCIOS_14,
  SOCIOS_15,
  SOCIOS_16,
  SOCIOS_17,
  SOCIOS_18,
  SOCIOS_19,
  SOCIOS_20,
  SOCIOS_21,
  SOCIOS_22,
  SOCIOS_23,
  SOCIOS_24,
  SOCIOS_25,
  SOCIOS_26,
  SOCIOS_27,
  SOCIOS_28,
  SOCIOS_29,
  SOCIOS_30,
  SOCIOS_31,
  SOCIOS_32,
  SOCIOS_33,
  SOCIOS_34,
  SOCIOS_35,
  SOCIOS_36,
  SOCIOS_37,
  SOCIOS_38,
  SOCIOS_39,
  SOCIOS_40,
  SOCIOS_41,
  SOCIOS_42,
  SOCIOS_43,
  SOCIOS_44,
  SOCIOS_45,
  SOCIOS_46,
  SOCIOS_47,
  SOCIOS_48,
  TOTAL_DE_SOCIOS,
  RENUNCIAS_SOCIOS,
  DIRECTORS_1,
  DIRECTORS_2,
  DIRECTORS_3,
  DIRECTORS_4,
  DIRECTORS_5,
  DIRECTORS_6,
  DIRECTORS_7,
  DIRECTORS_8,
  DIRECTORS_9,
  DIRECTORS_10,
  DIRECTORS_11,
  DIRECTORS_12,
  DIRECTORS_13,
  DIRECTORS_14,
  DIRECTORS_15,
  DIRECTORS_16,
  PROMOVIDOS_A_SOCIOS,
  RENUNCIAS_DIRECTORES,
  DESPIDOS_DIECTORES,
  TOTAL_DE_DIRECTORES,
  AB,
  AB_1,
  AB_2,
  AB_3,
  AB_4,
  AB_5,
  AB_6,
  AB_7,
  AB_8,
  AB_9,
  AB_10,
  AB_11,
  PROMOVIDOS_A_DIRECTORES,
  RENUNCIAS_ASISTENTES,
  DESPIDOS_ASISTENTES,
  TOTAL_DE_ASISTENTES,
  STAFF_DE_SOPORTE_NOVATO,
  STAFF_DE_SOPORTE_EXPERIMENTADO,
} = STAFF

const variablesPartnersFromOneToFourYears = [
  SOCIOS_1,
  SOCIOS_2,
  SOCIOS_3,
  SOCIOS_4,
  SOCIOS_5,
  SOCIOS_6,
  SOCIOS_7,
  SOCIOS_8,
  SOCIOS_9,
  SOCIOS_10,
  SOCIOS_11,
  SOCIOS_12,
  SOCIOS_13,
  SOCIOS_14,
  SOCIOS_15,
  SOCIOS_16,
]

const getVariablesPartnersFromOneToFourYears = variables => ({
  type: GET_PARTNERS_FROM_ONE_TO_FOUR_YEARS,
  variables,
})

const fetchVariablesPartnersFromOneToFourYears = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPartnersFromOneToFourYears)
    dispatch(getVariablesPartnersFromOneToFourYears(variables))
  }
}

const variablesPartnersFromSixToEightYears = [
  SOCIOS_17,
  SOCIOS_18,
  SOCIOS_19,
  SOCIOS_20,
  SOCIOS_21,
  SOCIOS_22,
  SOCIOS_23,
  SOCIOS_24,
  SOCIOS_25,
  SOCIOS_26,
  SOCIOS_27,
  SOCIOS_28,
  SOCIOS_29,
  SOCIOS_30,
  SOCIOS_31,
  SOCIOS_32,
]

const getVariablesPartnersFromSixToEightYears = variables => ({
  type: GET_PARTNERS_FROM_SIX_TO_EIGHT_YEARS,
  variables,
})

const fetchVariablesPartnersFromSixToEightYears = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPartnersFromSixToEightYears)
    dispatch(getVariablesPartnersFromSixToEightYears(variables))
  }
}

const variablesPartnersFromNineToTwelveYears = [
  SOCIOS_33,
  SOCIOS_34,
  SOCIOS_35,
  SOCIOS_36,
  SOCIOS_37,
  SOCIOS_38,
  SOCIOS_39,
  SOCIOS_40,
  SOCIOS_41,
  SOCIOS_42,
  SOCIOS_43,
  SOCIOS_44,
  SOCIOS_45,
  SOCIOS_46,
  SOCIOS_47,
  SOCIOS_48,
]

const getVariablesPartnersFromNineToTwelveYears = variables => ({
  type: GET_PARTNERS_FROM_NINE_TO_TWELVE_YEARS,
  variables,
})

const fetchVariablesPartnersFromNineToTwelveYears = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPartnersFromNineToTwelveYears)
    dispatch(getVariablesPartnersFromNineToTwelveYears(variables))
  }
}

const variablesPartnersList = [TOTAL_DE_SOCIOS, RENUNCIAS_SOCIOS]

const getVariablesPartnersList = variables => ({
  type: GET_VARIABLES_PARTNERS_LIST,
  variables,
})

const fetchVariablesPartnersList = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPartnersList)
    dispatch(getVariablesPartnersList(variables))
  }
}

const variablesDirectorsFromOneToFourYears = [
  DIRECTORS_1,
  DIRECTORS_2,
  DIRECTORS_3,
  DIRECTORS_4,
  DIRECTORS_5,
  DIRECTORS_6,
  DIRECTORS_7,
  DIRECTORS_8,
  DIRECTORS_9,
  DIRECTORS_10,
  DIRECTORS_11,
  DIRECTORS_12,
  DIRECTORS_13,
  DIRECTORS_14,
  DIRECTORS_15,
  DIRECTORS_16,
]

const getVariablesDirectorsFromOneToFourYears = variables => ({
  type: GET_DIRECTORS_FROM_ONE_TO_FOUR_YEARS,
  variables,
})

const fetchVariablesDirectorsFromOneToFourYears = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesDirectorsFromOneToFourYears)
    dispatch(getVariablesDirectorsFromOneToFourYears(variables))
  }
}

const variablesDirectorsList = [
  PROMOVIDOS_A_SOCIOS,
  RENUNCIAS_DIRECTORES,
  DESPIDOS_DIECTORES,
  TOTAL_DE_DIRECTORES,
]

const getVariablesDirectorsList = variables => ({
  type: GET_VARIABLES_DIRECTORS_LIST,
  variables,
})

const fetchVariablesDirectorsList = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesDirectorsList)
    dispatch(getVariablesDirectorsList(variables))
  }
}

const variablesAb = [AB, AB_1, AB_2, AB_3, AB_4, AB_5, AB_6, AB_7, AB_8, AB_9, AB_10, AB_11]

const getVariablesAb = variables => ({
  type: GET_VARIABLES_AB,
  variables,
})

const fetchVariablesVariablesAb = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesAb)
    dispatch(getVariablesAb(variables))
  }
}

const variablesAbList = [
  PROMOVIDOS_A_DIRECTORES,
  RENUNCIAS_ASISTENTES,
  DESPIDOS_ASISTENTES,
  TOTAL_DE_ASISTENTES,
  STAFF_DE_SOPORTE_NOVATO,
  STAFF_DE_SOPORTE_EXPERIMENTADO,
]

const getVariablesAbList = variables => ({
  type: GET_VARIABLES_LIST_AB,
  variables,
})

const fetchVariablesAbList = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesAbList)
    dispatch(getVariablesAbList(variables))
  }
}

const allFuntionsFetchStaff = [
  fetchVariablesPartnersFromOneToFourYears,
  fetchVariablesPartnersFromSixToEightYears,
  fetchVariablesPartnersFromNineToTwelveYears,
  fetchVariablesDirectorsFromOneToFourYears,
  fetchVariablesPartnersList,
  fetchVariablesDirectorsList,
  fetchVariablesVariablesAb,
  fetchVariablesAbList,
]

export {
  fetchVariablesPartnersFromOneToFourYears,
  fetchVariablesPartnersFromSixToEightYears,
  fetchVariablesPartnersFromNineToTwelveYears,
  fetchVariablesDirectorsFromOneToFourYears,
  fetchVariablesPartnersList,
  fetchVariablesDirectorsList,
  fetchVariablesVariablesAb,
  fetchVariablesAbList,
  allFuntionsFetchStaff,
}
