import React, { useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Menu from 'common/Menu'

import { Route } from 'react-router-dom'
import { withRouter } from 'react-router'
import allDispatch from 'common/allDispatch'
import { BASE } from 'constants/routes'
import Home from 'routes/Home'
import style from './style'

const BodyWithStyle = ({ isExact }) => {
  const classes = style()
  const dispatch = useDispatch()
  const getVariables = useCallback(async () => {
    await allDispatch(dispatch)
  }, [dispatch])

  useEffect(() => {
    getVariables()
  }, [getVariables])

  if (isExact) {
    return (
      <div className={classes.container}>
        <Box className="mainBox">
          <Home />
        </Box>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <Box className="mainBox">
        {Menu().map(item =>
          item.path !== BASE ? (
            <Route key={`${item.name}-route`} path={item.path} component={item.component} />
          ) : null,
        )}
      </Box>
    </div>
  )
}

class Body extends React.Component {
  render() {
    const { match } = this.props
    const { isExact } = match

    return <BodyWithStyle isExact={isExact} />
  }
}

export default withRouter(Body)
