import React from 'react'
import { Button } from '@material-ui/core'
import { logout } from '../services/authService'

const LogoutButton = () => {
  return (
    <Button variant="outlined" onClick={logout}>
      Logout
    </Button>
  )
}

export default LogoutButton
