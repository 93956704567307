import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import { ConfigProvider } from 'common/ConfigProvider'
import ErrorModal from 'components/Modals/ErrorModal'
import ShareLink from 'components/ShareLink'
import { logout } from 'services/authService'
import { loadLocalStorageValue, localStorageKeys } from 'services/localstorage'
import Header from '../Header'
import SideBar from '../SideBar'
import Body from '../Body'
import { userLayoutStyle } from './style'

const handleErrorModal = () => {
  logout()
}

const UserLayout = () => {
  const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)

  if (!teamInformation) {
    return (
      <ErrorModal
        title="Oops"
        openErrorModal
        message="You have not joined any simulation"
        handleErrorModal={handleErrorModal}
      />
    )
  }

  const classes = userLayoutStyle()
  return (
    <div className={classes.root}>
      <CssBaseline />
      <ConfigProvider>
        <Header />
        <SideBar />
        <ShareLink />
      </ConfigProvider>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Body />
      </main>
    </div>
  )
}

export default UserLayout
