import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Footer from '../../layouts/Footer'

const useStyles = makeStyles({
  hero: {
    textAlign: 'center',
  },
  heroImage: {
    width: '60%',
  },
  textBox: {
    textAlign: 'justify',
    fontSize: '13px',
  },
})

const Home = () => {
  const classes = useStyles()

  return (
    <div>
      <Box>
        <div className={classes.hero}>
          <img
            className={classes.heroImage}
            src="assets/images/background-hero1.jpg"
            alt="background"
          />
        </div>
        <div className={classes.textBox}>
          <p>
            This simulation and accompanying documentation has been developed by Processim Labs. No
            part of the simulation or accompanying documentation may be reproduced, stored in a
            retrieval system, used in spreadsheet or electronic programs, or transmitted in any form
            or by any means electronic,mechanical, recording, photocopying, or any other without the
            written permission of the owners.
          </p>
          <p>
            This simulation was prepared with the sole purpose of providing a background for class
            discussion. The simulation has been developed solely as the basis of class discussion.
            The simulation and accompanying materials are not intended to serve as any sort of
            endorsement. The simulation and accompanying materials are not intended to replicate the
            behavior of any company. The simulation and accompanying materials are not intended to
            illustrate effective or ineffective management. The simulation and accompanying
            materials are not intended to forecast the effects of a policy or combinations of
            policies on any actual company.
          </p>
        </div>
      </Box>
      <Footer />
    </div>
  )
}

export default Home
