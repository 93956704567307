import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { EXPERIENCE, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { SMOOTHING_DE_EXPERIENCIA_FORMULA_SENCILLA_EN_DIAS, EXPERIENCIA_DE_DIRECTORES } = EXPERIENCE
const { TIME } = HEADER
const { ASSISTANS, DIRECTORS } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: ASSISTANS,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[SMOOTHING_DE_EXPERIENCIA_FORMULA_SENCILLA_EN_DIAS],
      },
      {
        label: DIRECTORS,
        fill: false,
        borderColor: '#FFCE56',
        data: variables[EXPERIENCIA_DE_DIRECTORES],
      },
    ],
  }
}

const MeanExperience = () => {
  const variables = useSelector(state => state.experience.currentValues.variablesMeanExperience)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleChart="Mean Experience" />
  ) : null
}
export default MeanExperience
