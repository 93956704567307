import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { Results, Balances } from './List'
import { ProfitBeforeTaxes } from './Labels'

const Finances = () => {
  const stateFinances = useSelector(state => state.finances)
  if (validateStates(stateFinances)) {
    return <Loading />
  }

  return (
    <div>
      <Box display="flex" alignItems="center">
        <Box className="smallElementWrapper elementToLeft">
          <ProfitBeforeTaxes />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <h2>Results</h2>
          <Results />
        </Box>
        <Box className="elementWrapper elementToRight">
          <h2>Balances</h2>
          <Balances />
        </Box>
      </Box>
    </div>
  )
}

export default Finances
