import {
  GET_FIRED_DECISION,
  CHANGE_VARIABLE_STAFFING,
  GET_HIRING_DECISIONS,
  GET_PROMOTIONS,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId } from 'services/forio/forio'
import { STAFFING } from 'constants/forioVariables'

const {
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE,
  DECISIONES_DE_CONTRATACION_LATERAL,
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO,
  DECISION_DE_PROMOCION_DE_ABS,
  DECISION_DE_PROMOCION_A_SOCIOS,
} = STAFFING

const variablesFiredDecision = [
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
]

const getVariablesFiredDecision = variables => ({
  type: GET_FIRED_DECISION,
  variables,
})

const fetchVariablesFiredDecision = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesFiredDecision)
    dispatch(getVariablesFiredDecision(variables))
  }
}

const changeStaffingVariable = payload => {
  return { type: CHANGE_VARIABLE_STAFFING, payload }
}

const variablesHiringDecision = [
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE,
  DECISIONES_DE_CONTRATACION_LATERAL,
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO,
]

const getVariablesHiringDecision = variables => ({
  type: GET_HIRING_DECISIONS,
  variables,
})

const fetchVariablesHiringDecision = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesHiringDecision)
    dispatch(getVariablesHiringDecision(variables))
  }
}

const variablesPromotions = [DECISION_DE_PROMOCION_DE_ABS, DECISION_DE_PROMOCION_A_SOCIOS]

const getVariablePromotions = variables => ({
  type: GET_PROMOTIONS,
  variables,
})

const fetchVariablesPromotions = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesPromotions)
    dispatch(getVariablePromotions(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFuntionsFetchStaffing = [
  fetchVariablesFiredDecision,
  fetchVariablesHiringDecision,
  fetchVariablesPromotions,
]

export {
  changeStaffingVariable,
  fetchVariablesFiredDecision,
  allFuntionsFetchStaffing,
  clearNewValues,
}
