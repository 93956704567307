import React from 'react'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import { validateStates } from 'common/states'
import Loading from 'components/Loading'
import { CONSTANTS } from 'constants/constants'
import { PartnersList, DirectorsList, AbList, SupportStaff } from './Lists'
import { AssistantsTenureTable, PartnersTenureTable, DirectorsTenureTable } from './Tables'

const { PARTNERS, DIRECTORS, ASSISTANTS, SUPPORT_STAFF } = CONSTANTS

const Headcount = () => {
  const stateStaff = useSelector(state => state.staff)
  if (validateStates(stateStaff)) {
    return <Loading />
  }
  return (
    <div>
      <h2>{PARTNERS}</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapperFull">
          <PartnersTenureTable />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <PartnersList />
      </Box>

      <h2>{DIRECTORS}</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <DirectorsTenureTable />
        </Box>
        <Box className="elementWrapper elementToRight">
          <DirectorsList />
        </Box>
      </Box>

      <h2>{ASSISTANTS}</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <AssistantsTenureTable />
        </Box>
        <Box className="elementWrapper elementToRight">
          <AbList />
        </Box>
      </Box>

      <h2>{SUPPORT_STAFF}</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <SupportStaff />
        </Box>
      </Box>
    </div>
  )
}

export default Headcount
