import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { MARKET } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { formatVariables } from 'common/utils'

const { SUCURSALES_DE_LA_COMPETENCIA } = MARKET
const { COMPETITION_BRANCH_OFFICE } = CONSTANTS
const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-MarketList',
    label: COMPETITION_BRANCH_OFFICE,
    component: (
      <Typography style={classLabel}>
        {Math.round(variables[SUCURSALES_DE_LA_COMPETENCIA])}
      </Typography>
    ),
  },
]

const BranchOfTheCompetition = () => {
  const variables = useSelector(state => state.market.variablesMarketLabels)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default BranchOfTheCompetition
