import { FORIO_API_URL } from '../../constants/appConfig'
import { loadLocalStorageValue, localStorageKeys } from '../localstorage'

const getVariablesLastValueByRunId = async variables => {
  try {
    const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)
    if (!teamInformation) {
      console.log('There is not run')
      return []
    }
    const response = await fetch(
      `${FORIO_API_URL}/${teamInformation.forioRunId}?include=${variables.join()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-AutoRestore': true,
        },
      },
    )
    const jsonResponse = await response.json()
    const responseVariables = jsonResponse.variables
    const variablesNames = Object.keys(jsonResponse.variables)
    for (const variable of variablesNames) {
      const arrayLength = responseVariables[variable].length
      responseVariables[variable] = responseVariables[variable][arrayLength - 1]
    }

    return responseVariables
  } catch (error) {
    console.log(error, 'ERROR')
  }
}

const getVariablesByRunId = async variables => {
  try {
    const teamInformation = loadLocalStorageValue(localStorageKeys.TEAM_INFORMATION)
    if (!teamInformation) {
      console.log('There is not run')
      return []
    }
    const response = await fetch(
      `${FORIO_API_URL}/${teamInformation.forioRunId}?include=${variables.join()}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-AutoRestore': true,
        },
      },
    )
    const jsonResponse = await response.json()
    const responseVariables = jsonResponse.variables

    return responseVariables
  } catch (error) {
    console.log(error, 'ERROR')
  }
}

export { getVariablesLastValueByRunId, getVariablesByRunId }
