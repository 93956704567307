import React from 'react'
import { useSelector } from 'react-redux'
import { STAFF } from 'constants/forioVariables'
import Table from 'components/Table'
import { makeStyles, Typography } from '@material-ui/core'

const {
  DIRECTORS_1,
  DIRECTORS_2,
  DIRECTORS_3,
  DIRECTORS_4,
  DIRECTORS_5,
  DIRECTORS_6,
  DIRECTORS_7,
  DIRECTORS_8,
  DIRECTORS_9,
  DIRECTORS_10,
  DIRECTORS_11,
  DIRECTORS_12,
  DIRECTORS_13,
  DIRECTORS_14,
  DIRECTORS_15,
  DIRECTORS_16,
} = STAFF

const useStyle = makeStyles(() => ({
  blueLetters: {
    color: '#4BC0C0',
    fontWeight: 'bold',
  },
}))

const renderCellBuilder = classes => {
  const renderCell = variable => {
    const styles = []
    if (variable !== 0) {
      styles.push(classes.blueLetters)
    }
    return <Typography className={styles}>{variable}</Typography>
  }

  return renderCell
}

const returnData = (variables, classes) => {
  const renderCell = renderCellBuilder(classes)
  return [
    [
      'First Quarter',
      renderCell(variables[DIRECTORS_1]),
      renderCell(variables[DIRECTORS_5]),
      renderCell(variables[DIRECTORS_9]),
      renderCell(variables[DIRECTORS_13]),
    ],
    [
      'Second Quarter',
      renderCell(variables[DIRECTORS_2]),
      renderCell(variables[DIRECTORS_6]),
      renderCell(variables[DIRECTORS_10]),
      renderCell(variables[DIRECTORS_14]),
    ],
    [
      'Third Quarter',
      renderCell(variables[DIRECTORS_3]),
      renderCell(variables[DIRECTORS_7]),
      renderCell(variables[DIRECTORS_11]),
      renderCell(variables[DIRECTORS_15]),
    ],
    [
      'Fourth Quarter',
      renderCell(variables[DIRECTORS_4]),
      renderCell(variables[DIRECTORS_8]),
      renderCell(variables[DIRECTORS_12]),
      renderCell(variables[DIRECTORS_16]),
    ],
  ]
}

const columns = () => [
  { type: 'label', name: 'Quarter' },
  { type: 'label', name: 'Year 1' },
  { type: 'label', name: 'Year 2' },
  { type: 'label', name: 'Year 3' },
]

const DirectorsTenureTable = () => {
  const variables = useSelector(state => state.staff.variablesDirectorsFromOneToFourYears)
  const classes = useStyle()

  return (
    <Table
      titleColor="#4C7A1D"
      titleTable="Directors Tenure Table"
      columns={columns()}
      data={returnData(variables, classes)}
    />
  )
}
export default DirectorsTenureTable
