import { MARKETING, CAPACITY, EXPERIENCE, STAFFING, SALARIES } from 'constants/forioVariables'

const {
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I,
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I,
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I,
  DECISION_DE_DIAS_DE_CREDITO_REGION_I,
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
} = MARKETING

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
} = CAPACITY

const {
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_DIRECTORES,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,

  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
} = EXPERIENCE

const {
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE,
  DECISIONES_DE_CONTRATACION_LATERAL,
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO,
  DECISION_DE_PROMOCION_DE_ABS,
  DECISION_DE_PROMOCION_A_SOCIOS,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
} = STAFFING

const {
  DECISION_DE_SALARIOS_ABS,
  DECISION_DE_SALARIOS_DIRECTORES,
  DECISION_DE_SALARIOS_SOCIOS,
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
  DECISION_BONO_DE_CONTRARACION_ABS,
  DECISION_BONO_DE_CONTRARACION_LATERAL,
} = SALARIES

const allowUpdate = [
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I,
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I,
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I,
  DECISION_DE_DIAS_DE_CREDITO_REGION_I,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUCIOS__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_ASISTENTES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SEGMENTO_PURETE_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR__REGION_I_SEGMENTO_PURETE_SOCIOS,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_ASISTENTES,
  DECISIONES_HORAS_LABORALES_EXIGIDAS_A_DIRECTORES,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AL_SEGMENTO_FINO,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_SUPERVISOR,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_LA_MENTORIA,
  DECISIONES_DE_PORCENTAJE_DE_TIEMPO_DE_LOS_DIRECTORES_DEDICADOS_A_CONSEGUIR_CLIENTES,
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE,
  DECISIONES_DE_CONTRATACION_LATERAL,
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO,
  DECISION_DE_PROMOCION_DE_ABS,
  DECISION_DE_PROMOCION_A_SOCIOS,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
  DECISION_DE_SALARIOS_ABS,
  DECISION_DE_SALARIOS_DIRECTORES,
  DECISION_DE_SALARIOS_SOCIOS,
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
  DECISION_BONO_DE_CONTRARACION_ABS,
  DECISION_BONO_DE_CONTRARACION_LATERAL,
]

const seletedVariablesToUpdate = variables => {
  let variablesToUpdate = {}
  for (const key in variables) {
    if (variables) {
      // eslint-disable-next-line no-loop-func
      allowUpdate.forEach(allow => {
        if (`${key}` === `${allow}`) {
          variablesToUpdate = {
            ...variablesToUpdate,
            [`${key}`]: variables[key],
          }
        }
      })
    }
  }
  return variablesToUpdate
}

export default seletedVariablesToUpdate
