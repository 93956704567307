export const CHECK_AUTH = '/'
export const CALLBACK = '/callback'
export const BASE = '/app'
export const CREATE_TEAM_PREAMBLE = '/CreateTeamPreamble'
export const CREATE_TEAM = '/CreateTeam'
export const JOIN_TEAM_PREAMBLE = '/JoinTeamPreamble'
export const JOIN_TEAM = '/JoinTeam'
export const SIMULATIONS = '/Simulations'
export const CAPACITY = `${BASE}/capacity`
export const EXPERIENCE = `${BASE}/experience`
export const FINANCES = `${BASE}/finances`
export const HEADCOUNT = `${BASE}/headcount`
export const HOME = `${BASE}/home`
export const MARKET = `${BASE}/market`
export const MARKETING = `${BASE}/marketing`
export const QUALITY = `${BASE}/quality`
export const SALARIES = `${BASE}/salaries`
export const STAFFING = `${BASE}/staffing`
