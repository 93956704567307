import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/staffingActions'
import { FiredDecision } from './TableDecisions'
import { HiringDecisions, Promotions } from './ListDecisions'

const Staffing = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateStaffing = useSelector(state => state.staffing.currentValues)
  if (validateStates(stateStaffing)) {
    return <Loading />
  }

  return (
    <div>
      <h2>Hiring and Promotions</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <HiringDecisions />
        </Box>
        <Box className="elementWrapper elementToRight">
          <Promotions />
        </Box>
      </Box>
      <h2>Layoffs</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <FiredDecision />
        </Box>
      </Box>
    </div>
  )
}

export default Staffing
