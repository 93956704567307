import { allFuntionsFetchMarketing } from 'actions/marketingActions'
import { allFuntionsFetchCapacity } from 'actions/capacityActions'
import { allFuntionsFetchHeader } from 'actions/headerActions'
import { allFuntionsFetchStaffing } from 'actions/staffingActions'
import { allFuntionsFetchExperience } from 'actions/experienceActions'
import { allFuntionsFetchStaff } from 'actions/staffActions'
import { allFuntionsFetchQuality } from 'actions/qualityActions'
import { allFuntionsFetchFinances } from 'actions/financesActions'
import { allFuntionsFetchMarket } from 'actions/marketActions'
import { allFuntionsFetchSalaries } from 'actions/salariesActions'

import { CLEAR_VALUES } from 'constants/actionTypes'

const allDispatch = async dispatch => {
  await dispatch({ type: CLEAR_VALUES })
  await allFuntionsFetchMarketing.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchCapacity.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchHeader.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchStaffing.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchExperience.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchQuality.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchFinances.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchMarket.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchSalaries.forEach(funtion => dispatch(funtion()))
  await allFuntionsFetchStaff.forEach(funtion => dispatch(funtion()))
}

export default allDispatch
