import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { STAFF } from 'constants/forioVariables'
import { roundVariables } from 'common/utils'

const { INEXPERIENCED_SUPPORT_STAFF, EXPERIENCED_SUPPORT_STAFF } = CONSTANTS

const { STAFF_DE_SOPORTE_NOVATO, STAFF_DE_SOPORTE_EXPERIMENTADO } = STAFF

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-SupportStaff',
    label: INEXPERIENCED_SUPPORT_STAFF,
    component: <Typography style={classLabel}>{variables[STAFF_DE_SOPORTE_NOVATO]}</Typography>,
  },
  {
    id: '2-SupportStaff',
    label: EXPERIENCED_SUPPORT_STAFF,
    component: (
      <Typography style={classLabel}>{variables[STAFF_DE_SOPORTE_EXPERIMENTADO]}</Typography>
    ),
  },
]
const SupportStaff = () => {
  const variables = useSelector(state => state.staff.variablesAbList)
  const formattedVariables = roundVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default SupportStaff
