import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyle = makeStyles(() => ({
  title: {
    flexGrow: 1,
    textTransform: 'capitalize',
    color: 'red',
  },
}))

const LabelTitleValidation = ({ title, whichColumn, columnValidate }) => {
  const classes = useStyle()
  const isError = whichColumn && whichColumn.indexOf(columnValidate) > -1
  return <span className={isError ? classes.title : null}>{title}</span>
}
export default LabelTitleValidation
