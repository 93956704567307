import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { FINANCES } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const { UAI } = FINANCES
const { PROFIT_BEFORE_TAXES } = CONSTANTS
const classLabel = {
  fontSize: '1.2rem',
  fontWeight: 'bold',
}

const returnElements = variables => [
  {
    id: '1-ProfitsList',
    label: PROFIT_BEFORE_TAXES,
    component: <Typography style={classLabel}>${variables[UAI]}</Typography>,
  },
]

const ProfitBeforeTaxes = () => {
  const variables = useSelector(state => state.finances.variablesResults)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}

export default ProfitBeforeTaxes
