import Marketing from 'routes/Marketing'
import Capacity from 'routes/Capacity'
import Experience from 'routes/Experience'
import Headcount from 'routes/Headcount'
import Staffing from 'routes/Staffing'
import Salaries from 'routes/Salaries'
import Market from 'routes/Market'
import Quality from 'routes/Quality'
import Finances from 'routes/Finances'
import * as ROUTES from 'constants/routes'
import {
  faAd,
  faClipboardList,
  faMedal,
  faUsers,
  faAddressBook,
  faMoneyBillAlt,
  faStore,
  faAward,
  faDollarSign,
  faHome,
} from '@fortawesome/free-solid-svg-icons'

const Menu = () => [
  {
    name: 'Home',
    icon: faHome,
    path: ROUTES.BASE,
  },
  {
    name: 'Marketing',
    icon: faAd,
    path: ROUTES.MARKETING,
    component: Marketing,
  },
  {
    name: 'Capacity',
    icon: faClipboardList,
    path: ROUTES.CAPACITY,
    component: Capacity,
  },
  {
    name: 'Experience',
    icon: faMedal,
    path: ROUTES.EXPERIENCE,
    component: Experience,
  },
  {
    name: 'Headcount',
    icon: faUsers,
    path: ROUTES.HEADCOUNT,
    component: Headcount,
  },
  {
    name: 'Staffing',
    icon: faAddressBook,
    path: ROUTES.STAFFING,
    component: Staffing,
  },
  {
    name: 'Salaries',
    icon: faMoneyBillAlt,
    path: ROUTES.SALARIES,
    component: Salaries,
  },
  {
    name: 'Market',
    icon: faStore,
    path: ROUTES.MARKET,
    component: Market,
  },
  {
    name: 'Quality',
    icon: faAward,
    path: ROUTES.QUALITY,
    component: Quality,
  },
  {
    name: 'Finances',
    icon: faDollarSign,
    path: ROUTES.FINANCES,
    component: Finances,
  },
]

export default Menu
