import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import InputNumber from 'components/InputNumber'
// import RangeSlider from 'components/RangeSlider'
import { CONSTANTS } from 'constants/constants'
import { STAFFING } from 'constants/forioVariables'
import { changeStaffingVariable } from 'actions/staffingActions'
import { changeValue as onChangeValue } from 'common'

const { DECISION_DE_PROMOCION_DE_ABS, DECISION_DE_PROMOCION_A_SOCIOS } = STAFFING

const { PROMOTION_TO_DIRECTOR, PROMOTION_TO_PARTNER } = CONSTANTS

const returnElements = (variables, changeValue) => [
  {
    id: '1-Promotions',
    label: PROMOTION_TO_DIRECTOR,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_PROMOCION_DE_ABS]}
        id={DECISION_DE_PROMOCION_DE_ABS}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '2-Promotions',
    label: PROMOTION_TO_PARTNER,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_PROMOCION_A_SOCIOS]}
        id={DECISION_DE_PROMOCION_A_SOCIOS}
        change={event => changeValue(event)}
      />
    ),
  },
]
const Promotions = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesPromotions', dispatch, changeStaffingVariable)
  const variables = useSelector(state => state.staffing.currentValues.variablesPromotions)

  return <List items={returnElements(variables, changeValue)} />
}
export default Promotions
