import {
  GET_VARIBLES_HOUR_TO_CARRY,
  GET_VARIBLES_SALES_THIS_QUATER,
  GET_VARIBLES_LIST,
  GET_VARIBLES_CHART_POPULAR_SEGMENT,
  GET_VARIBLES_CHART_CORPORATE_SEGMENT,
  CHANGE_VARIABLE_MARKETING,
  CLEAR_VALUES,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'

const initialMarketing = {
  currentValues: {
    variablesHourToCarry: {},
    variablesSalesThisQuater: {},
    variablesList: {},
    variablesChartPopularSegments: {},
    variablesChartCorporateSegments: {},
  },
  newValues: {},
}

const Marketing = (state = initialMarketing, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialMarketing
    case GET_VARIBLES_HOUR_TO_CARRY:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesHourToCarry: action.variables,
        },
      }
    case GET_VARIBLES_SALES_THIS_QUATER:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesSalesThisQuater: action.variables,
        },
      }
    case GET_VARIBLES_LIST:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesList: action.variables,
        },
      }
    case GET_VARIBLES_CHART_POPULAR_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartPopularSegments: action.variables,
        },
      }
    case GET_VARIBLES_CHART_CORPORATE_SEGMENT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesChartCorporateSegments: action.variables,
        },
      }
    case CHANGE_VARIABLE_MARKETING:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Marketing
