import {
  GET_VARIBLES_PERCEIVED_REPUTATION,
  GET_VARIBLES_PERCEIVED_QUALITY,
  GET_VARIBLES_CLIENT_TO_LAWYER_RATIO,
  CHANGE_VARIABLE_QUALITY,
} from 'constants/actionTypes'
import { getVariablesByRunId } from 'services/forio/forio'
import { QUALITY } from 'constants/forioVariables'

const { REPUTACION_PERCIBIDA, CALIDAD_PERCIBIDA, RAZON_CLIENTES_A_ABOGADOS_TOTALES } = QUALITY

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_QUALITY, payload }
}

const variablesChartPerceivedReputation = [REPUTACION_PERCIBIDA]

const getVariablesChartPerceivedReputation = variables => ({
  type: GET_VARIBLES_PERCEIVED_REPUTATION,
  variables,
})

const fetchVariablesChartPerceivedReputation = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartPerceivedReputation)
    dispatch(getVariablesChartPerceivedReputation(variables))
  }
}

const variablesChartPerceivedQuality = [CALIDAD_PERCIBIDA]

const getVariablesChartPerceivedQuality = variables => ({
  type: GET_VARIBLES_PERCEIVED_QUALITY,
  variables,
})

const fetchVariablesChartPerceivedQuality = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartPerceivedQuality)
    dispatch(getVariablesChartPerceivedQuality(variables))
  }
}

const variablesChartClientToLawyerRatio = [RAZON_CLIENTES_A_ABOGADOS_TOTALES]

const getVariablesChartClientToLawyerRatio = variables => ({
  type: GET_VARIBLES_CLIENT_TO_LAWYER_RATIO,
  variables,
})

const fetchVariablesChartClientToLawyerRatio = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartClientToLawyerRatio)
    dispatch(getVariablesChartClientToLawyerRatio(variables))
  }
}

const allFuntionsFetchQuality = [
  fetchVariablesChartPerceivedReputation,
  fetchVariablesChartPerceivedQuality,
  fetchVariablesChartClientToLawyerRatio,
]

export {
  fetchVariablesChartPerceivedReputation,
  fetchVariablesChartPerceivedQuality,
  fetchVariablesChartClientToLawyerRatio,
  allFuntionsFetchQuality,
  changeVariable,
}
