import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import InputNumber from 'components/InputNumber'
// import RangeSlider from 'components/RangeSlider'
import { CONSTANTS } from 'constants/constants'
import { STAFFING } from 'constants/forioVariables'
import { changeStaffingVariable } from 'actions/staffingActions'
import { changeValue as onChangeValue } from 'common'

const {
  DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE,
  DECISIONES_DE_CONTRATACION_LATERAL,
  DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO,
} = STAFFING

const { HIRING_ASSITANT, HIRING_DIRECTORS, DESIRED_INEXPERIENCED_SUPPORT } = CONSTANTS

const returnElements = (variables, changeValue) => [
  {
    id: '1-HiringDecisions',
    label: HIRING_ASSITANT,
    component: (
      <InputNumber
        defaultValue={variables[DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE]}
        id={DECISIONES_DE_CONTRATACION_DEL_TRIMESTRE}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '2-HiringDecisions',
    label: HIRING_DIRECTORS,
    component: (
      <InputNumber
        defaultValue={variables[DECISIONES_DE_CONTRATACION_LATERAL]}
        id={DECISIONES_DE_CONTRATACION_LATERAL}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '3-HiringDecisions',
    label: DESIRED_INEXPERIENCED_SUPPORT,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO]}
        id={DECISION_DE_STAFF_DE_SOPORTE_NOVATO_DESEADO}
        change={event => changeValue(event)}
      />
    ),
  },
]
const HiringDecisions = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesHiringDecision', dispatch, changeStaffingVariable)
  const variables = useSelector(state => state.staffing.currentValues.variablesHiringDecision)

  return <List items={returnElements(variables, changeValue)} />
}
export default HiringDecisions
