import { GET_TIME } from 'constants/actionTypes'

const initialHeader = {
  variablesHeader: {},
  step: 0,
}

const Header = (state = initialHeader, action) => {
  switch (action.type) {
    case GET_TIME:
      return {
        ...state,
        variablesHeader: action.variables,
        step: action.step,
      }
    default:
      return state
  }
}

export default Header
