import React from 'react'
import MUIDataTable from 'mui-datatables'
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles'

import InputNumber from 'components/InputNumber'

const getMuiTheme = titleColor =>
  createMuiTheme({
    overrides: {
      MUIDataTable: {
        paper: {
          backgroundColor: '#3a4149',
          border: '1px solid #23282c',
          boxShadow: 'none',
          marginBottom: '1.5rem',
        },
      },
      MUIDataTableToolbar: {
        root: {
          borderBottom: '1px solid #23282c',
          backgroundColor: titleColor || '#119E9E',
          color: '#e4e7ea',
          padding: '.75rem 1.25rem',
          minHeight: 'unset',
        },
        titleText: {
          fontSize: '.875rem',
          textAlign: 'left',
          fontWeight: '400',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
        },
      },
      MUIDataTableHeadCell: {
        root: {
          backgroundColor: '#3a4149 !important',
          border: 'none',
          color: '#e4e7ea',
          fontSize: '.875rem',
          fontWeight: '400',
          padding: '1rem',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
        },
      },
      MUIDataTableBodyCell: {
        root: {
          border: 'none',
          color: '#e4e7ea',
          fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
          fontSize: '.875rem',
          padding: '1rem',
        },
      },
    },
  })

const options = {
  filter: false,
  viewColumns: false,
  download: false,
  print: false,
  search: false,
  sort: false,
  pagination: false,
  selectableRows: 'none',
  responsive: 'scroll',
  rowHover: false,
}

const returnColumns = columns => {
  return columns.map(column => {
    const { type, name } = column
    switch (type) {
      case 'inputNumber':
        return {
          name,
          options: {
            customBodyRender: value => {
              if (value) {
                return (
                  <InputNumber
                    defaultValue={value.value}
                    id={value.id}
                    label={name}
                    change={column.onChange}
                    max={column.max}
                    min={column.min}
                  />
                )
              } else {
                return null
              }
            },
          },
        }
      case 'label':
        return name
      default:
        return name
    }
  })
}

const Table = ({ titleTable, columns, data, titleColor }) => {
  return (
    <MuiThemeProvider theme={getMuiTheme(titleColor)}>
      <MUIDataTable
        title={titleTable}
        data={data}
        columns={returnColumns(columns)}
        options={options}
      />
    </MuiThemeProvider>
  )
}

Table.defaultProps = {
  titleTable: '',
  columns: [],
  data: [],
}

export default Table
