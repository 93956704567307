import {
  GET_VARIBLES_MEAN_ABILITY,
  GET_VARIBLES_MEAN_EXPERIENCE,
  GET_VARIBLES_EFECTIVE_TIME_AVAILABLE,
  GET_VARIBLES_BURNOUT,
  CHANGE_EXPERIENCE_VARIABLE,
  GET_VARIABLE_MENTORSHIP,
  CLEAR_VALUES,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'

const initialExperience = {
  currentValues: {
    variablesMeanAbility: {},
    variablesMeanExperience: {},
    variablesEfectiveTimeAvaliable: {},
    variablesBurnout: {},
    variablesFractionOfTimeUsedInMentorship: {},
  },
  newValues: {},
}

const Experience = (state = initialExperience, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialExperience
    case GET_VARIBLES_MEAN_ABILITY:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesMeanAbility: action.variables,
        },
      }
    case GET_VARIBLES_MEAN_EXPERIENCE:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesMeanExperience: action.variables,
        },
      }
    case GET_VARIBLES_EFECTIVE_TIME_AVAILABLE:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesEfectiveTimeAvaliable: action.variables,
        },
      }
    case GET_VARIBLES_BURNOUT:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesBurnout: action.variables,
        },
      }
    case GET_VARIABLE_MENTORSHIP:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesFractionOfTimeUsedInMentorship: action.variables,
        },
      }
    case CHANGE_EXPERIENCE_VARIABLE:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.currentValues[`${action.payload.variable}`],
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Experience
