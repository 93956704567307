import {
  GET_VARIBLES_HOUR_TO_CARRY,
  GET_VARIBLES_SALES_THIS_QUATER,
  GET_VARIBLES_LIST,
  GET_VARIBLES_CHART_POPULAR_SEGMENT,
  GET_VARIBLES_CHART_CORPORATE_SEGMENT,
  CHANGE_VARIABLE_MARKETING,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { MARKETING } from 'constants/forioVariables'

const {
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
  SALE_VENTAS_EN_DOLLARS_IYF_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_IYFF_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_FINO_ACC,
  SUCURSALES_REGION_I,
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I,
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I,
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I,
  DECISION_DE_DIAS_DE_CREDITO_REGION_I,
  MARKET_SHARE_PURETE_SMOOTHED,
  MARKET_SHARE_FINO_SMOOTHED,
} = MARKETING

const changeVariable = payload => {
  return { type: CHANGE_VARIABLE_MARKETING, payload }
}

const variablesHourToCarry = [
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
]

const getVariablesHourToCarry = variables => ({
  type: GET_VARIBLES_HOUR_TO_CARRY,
  variables,
})

const fetchVariablesHourToCarry = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesHourToCarry)
    dispatch(getVariablesHourToCarry(variables))
  }
}

const variablesSalesThisQuater = [
  SALE_VENTAS_EN_DOLLARS_IYF_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_IYFF_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_LYG_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_JUICIOS_FINO_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_PURETE_ACC,
  SALE_VENTAS_EN_DOLLARS_BR_FINO_ACC,
]

const getVariablesSalesThisQuater = variables => ({
  type: GET_VARIBLES_SALES_THIS_QUATER,
  variables,
})

const fetchVariablesSalesThisQuater = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesSalesThisQuater)
    dispatch(getVariablesSalesThisQuater(variables))
  }
}

const variablesList = [
  SUCURSALES_REGION_I,
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I,
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I,
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I,
  DECISION_DE_DIAS_DE_CREDITO_REGION_I,
]

const getVariablesList = variables => ({
  type: GET_VARIBLES_LIST,
  variables,
})

const fetchVariablesList = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesList)
    dispatch(getVariablesList(variables))
  }
}

const variablesChartPopularSegments = [MARKET_SHARE_PURETE_SMOOTHED]

const getVariablesChartPopularSegments = variables => ({
  type: GET_VARIBLES_CHART_POPULAR_SEGMENT,
  variables,
})

const fetchVariablesChartPopularSegment = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartPopularSegments)
    dispatch(getVariablesChartPopularSegments(variables))
  }
}

const variablesChartCorporateSegments = [MARKET_SHARE_FINO_SMOOTHED]

const getVariablesChartCorporateSegments = variables => ({
  type: GET_VARIBLES_CHART_CORPORATE_SEGMENT,
  variables,
})

const fetchVariablesChartCorporateSegments = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesChartCorporateSegments)
    dispatch(getVariablesChartCorporateSegments(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFuntionsFetchMarketing = [
  fetchVariablesHourToCarry,
  fetchVariablesSalesThisQuater,
  fetchVariablesList,
  fetchVariablesChartPopularSegment,
  fetchVariablesChartCorporateSegments,
]

export {
  changeVariable,
  fetchVariablesHourToCarry,
  fetchVariablesSalesThisQuater,
  fetchVariablesList,
  fetchVariablesChartPopularSegment,
  fetchVariablesChartCorporateSegments,
  allFuntionsFetchMarketing,
  clearNewValues,
}
