import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { FINANCES } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const {
  VENTAS,
  COMISIONES,
  INGRESOS_TOTALES_POR_VENTAS_Y_COMISIONES,
  SALARIOS,
  BONOS_Y_OTROS,
  SEGUROS,
  MERCADEO,
  LIBROS_Y_MATERIALES,
  OTROS_GASTOS_Y_DEPRECIACIONES,
  MALPRAXIS,
  COMUNICACIONES,
  UTILIDAD_OPERATIVA,
  INTERESES_PAGADOS,
  UAI,
  IMPUESTO_DE_LA_RENTA,
} = FINANCES

const {
  SALES,
  COMMISSION,
  REVENUE,
  SALARIES,
  BONUSES_AND_OTHERS,
  INSURANCES,
  MARKETING,
  BOOKS_AND_MATERIALS,
  MISCELANEOUS_EXPENSES_AND_DEPRECIATIONS,
  MALPRACTICE_EXPENCES,
  COMMUNICATIONS,
  OPERATING_INCOME,
  PAID_INTEREST,
  PROFIT_BEFORE_TAXES,
  TAXES,
} = CONSTANTS

const classLabel = {
  fontSize: '.875rem',
}

const renderElement = variable => {
  return <Typography style={classLabel}>{`$${variable}`}</Typography>
}

const returnElements = variables => [
  {
    id: '1-Results',
    label: SALES,
    component: renderElement(variables[VENTAS]),
  },
  {
    id: '2-Results',
    label: COMMISSION,
    component: renderElement(variables[COMISIONES]),
  },
  {
    id: '3-Results',
    label: REVENUE,
    component: renderElement(variables[INGRESOS_TOTALES_POR_VENTAS_Y_COMISIONES]),
  },
  {
    id: '4-Results',
    label: SALARIES,
    component: renderElement(variables[SALARIOS]),
  },
  {
    id: '5-Results',
    label: BONUSES_AND_OTHERS,
    component: renderElement(variables[BONOS_Y_OTROS]),
  },
  {
    id: '6-Results',
    label: INSURANCES,
    component: renderElement(variables[SEGUROS]),
  },
  {
    id: '7-Results',
    label: MARKETING,
    component: renderElement(variables[MERCADEO]),
  },
  {
    id: '8-Results',
    label: BOOKS_AND_MATERIALS,
    component: renderElement(variables[LIBROS_Y_MATERIALES]),
  },
  {
    id: '9-Results',
    label: MISCELANEOUS_EXPENSES_AND_DEPRECIATIONS,
    component: renderElement(variables[OTROS_GASTOS_Y_DEPRECIACIONES]),
  },
  {
    id: '10-Results',
    label: MALPRACTICE_EXPENCES,
    component: renderElement(variables[MALPRAXIS]),
  },
  {
    id: '11-Results',
    label: COMMUNICATIONS,
    component: renderElement(variables[COMUNICACIONES]),
  },
  {
    id: '12-Results',
    label: OPERATING_INCOME,
    component: renderElement(variables[UTILIDAD_OPERATIVA]),
  },
  {
    id: '13-Results',
    label: PAID_INTEREST,
    component: renderElement(variables[INTERESES_PAGADOS]),
  },
  {
    id: '14-Results',
    label: PROFIT_BEFORE_TAXES,
    component: renderElement(variables[UAI]),
  },
  {
    id: '15-Results',
    label: TAXES,
    component: renderElement(variables[IMPUESTO_DE_LA_RENTA]),
  },
]
const Results = () => {
  const variables = useSelector(state => state.finances.variablesResults)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default Results
