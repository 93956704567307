import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeStaffingVariable } from 'actions/staffingActions'
import { STAFFING } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { changeValue as onChangeValue } from 'common'

const {
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
  DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
  DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
} = STAFFING

const { FISRT_YEAR_HOURS, SECOND_YEAR_HOURS, THIRD_YEAR_HOURS, ASSISTANS, DIRECTORS } = CONSTANTS

const columns = changeValue => [
  { type: 'label', name: '' },
  { type: 'inputNumber', name: FISRT_YEAR_HOURS, onChange: changeValue },
  { type: 'inputNumber', name: SECOND_YEAR_HOURS, onChange: changeValue },
  { type: 'inputNumber', name: THIRD_YEAR_HOURS, onChange: changeValue },
]

const returnData = variables => [
  [
    ASSISTANS,
    {
      id: DECISION_DE_DESPIDO_DE_ASISTENTES_A_4,
      value: variables[DECISION_DE_DESPIDO_DE_ASISTENTES_A_4],
    },
    {
      id: DECISION_DE_DESPIDO_DE_ASISTENTES_A_8,
      value: variables[DECISION_DE_DESPIDO_DE_ASISTENTES_A_8],
    },
  ],
  [
    DIRECTORS,
    {
      id: DECISION_DE_DESPIDO_DE_DIRECTORES_D_4,
      value: variables[DECISION_DE_DESPIDO_DE_DIRECTORES_D_4],
    },
    {
      id: DECISION_DE_DESPIDO_DE_DIRECTORES_D_8,
      value: variables[DECISION_DE_DESPIDO_DE_DIRECTORES_D_8],
    },
    {
      id: DECISION_DE_DESPIDO_DE_DIRECTORES_D_12,
      value: variables[DECISION_DE_DESPIDO_DE_DIRECTORES_D_12],
    },
  ],
]

const FiredDecision = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesFiredDecision', dispatch, changeStaffingVariable)
  const variables = useSelector(state => state.staffing.currentValues.variablesFiredDecision)
  return <Table columns={columns(changeValue)} data={returnData(variables)} />
}

export default FiredDecision
