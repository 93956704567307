import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { EXPERIENCE } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const { FACTOR_DE_BURNOUT_ASISTENTES_SMOOTHED, FACTOR_DE_BURNOUT_DIRECTORES_SMOOTHED } = EXPERIENCE

const { ASSISTANS, DIRECTORS } = CONSTANTS

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-ExperienceBurnout',
    label: ASSISTANS,
    component: (
      <Typography style={classLabel}>{variables[FACTOR_DE_BURNOUT_ASISTENTES_SMOOTHED]}</Typography>
    ),
  },
  {
    id: '2-ExperienceBurnout',
    label: DIRECTORS,
    component: (
      <Typography style={classLabel}>{variables[FACTOR_DE_BURNOUT_DIRECTORES_SMOOTHED]}</Typography>
    ),
  },
]
const Burnout = () => {
  const variables = useSelector(state => state.experience.currentValues.variablesBurnout)
  const formattedVariables = formatVariables(variables)
  return (
    <List
      items={returnElements(formattedVariables)}
      listTitle="Burnout (0: no fatigue, 1: regular workload) "
    />
  )
}
export default Burnout
