import React from 'react'
import Box from '@material-ui/core/Box'
import { useSelector, useDispatch } from 'react-redux'
import Loading from 'components/Loading'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/experienceActions'
import { MeanAbility, MeanExperience, EfectiveTimeAvailable } from './Graphics'
import { Burnout } from './List'
import { AssistantShiftLengthInHours, DirectorShiftLengthInHours } from './NumberEdit'
import TableDecision from './TableDecision'

const Experience = () => {
  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateExperience = useSelector(state => state.experience.currentValues)
  if (validateStates(stateExperience)) {
    return <Loading />
  }

  return (
    <div>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <AssistantShiftLengthInHours />
        </Box>
        <Box className="elementWrapper elementToRight">
          <DirectorShiftLengthInHours />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <TableDecision />
        </Box>
        <Box className="elementWrapper elementToRight">
          <Burnout />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <MeanAbility />
        </Box>
        <Box className="elementWrapper elementToRight">
          <EfectiveTimeAvailable />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <MeanExperience />
        </Box>
      </Box>
    </div>
  )
}

export default Experience
