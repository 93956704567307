import { GET_MARKET_VARIABLES, GET_VARIABLE_MARKET_LABEL } from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { MARKET } from 'constants/forioVariables'

const {
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_FINO,
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_FINO,
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_FINO,
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_FINO,
  SUCURSALES_DE_LA_COMPETENCIA,
} = MARKET

const variablesMarketLabels = [SUCURSALES_DE_LA_COMPETENCIA]

const getVariablesMarketLabel = variables => ({
  type: GET_VARIABLE_MARKET_LABEL,
  variables,
})

const fetchVariablesMarketLabel = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesMarketLabels)
    dispatch(getVariablesMarketLabel(variables))
  }
}

const variablesMarketCharts = [
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_I_Y_REGION_I_FINO,
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_L_Y_G_REGION_I_FINO,
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_JUICIOS_REGION_FINO,
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_PURETE,
  PERCEPCION_PRECIO_ECONOMIA_B_R_REGION_I_FINO,
]

const getVariablesMarketCharts = variables => ({
  type: GET_MARKET_VARIABLES,
  variables,
})

const fetchVariablesMarketCharts = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesMarketCharts)
    dispatch(getVariablesMarketCharts(variables))
  }
}

const allFuntionsFetchMarket = [fetchVariablesMarketCharts, fetchVariablesMarketLabel]

export { allFuntionsFetchMarket }
