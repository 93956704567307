import React from 'react'
import Box from '@material-ui/core/Box'
import Loading from 'components/Loading'
import { useSelector } from 'react-redux'
import { validateStates } from 'common/states'

import {
  PopularMarketBiddingAndGovernment,
  PopularMarketRealState,
  PopularMarketTaxesAndFinances,
  PopularMarketTrials,
  PremiumMarketBiddingAndGovernment,
  PremiumMarketRealState,
  PremiumMarketTaxesAndFinances,
  PremiumMarketTrials,
  SegmentoPreferente,
  SegmentoPopular,
} from './Graphics'

import { BranchOfTheCompetition } from './Labels'

const Market = () => {
  const stateMarket = useSelector(state => state.market)
  if (validateStates(stateMarket)) {
    return <Loading />
  }
  return (
    <div>
      <Box display="flex" alignItems="center">
        <Box className="smallElementWrapper elementToLeft">
          <BranchOfTheCompetition />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <SegmentoPopular />
        </Box>
        <Box className="elementWrapper elementToRight">
          <SegmentoPreferente />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <h2>Mean prices in popular market (in $)</h2>
          <PopularMarketTaxesAndFinances />
          <PopularMarketBiddingAndGovernment />
          <PopularMarketTrials />
          <PopularMarketRealState />
        </Box>
        <Box className="elementWrapper elementToRight">
          <h2>Mean prices in corporate market (in $)</h2>
          <PremiumMarketTaxesAndFinances />
          <PremiumMarketBiddingAndGovernment />
          <PremiumMarketTrials />
          <PremiumMarketRealState />
        </Box>
      </Box>
    </div>
  )
}

export default Market
