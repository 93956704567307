import {
  GET_FIRED_DECISION,
  CHANGE_VARIABLE_STAFFING,
  GET_HIRING_DECISIONS,
  GET_PROMOTIONS,
  CLEAR_VALUES,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'

const initialStaffing = {
  currentValues: {
    variablesFiredDecision: {},
    variablesHiringDecision: {},
    variablesPromotions: {},
  },
  newValues: {},
}

const Staffing = (state = initialStaffing, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialStaffing
    case GET_FIRED_DECISION:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesFiredDecision: action.variables,
        },
      }
    case CHANGE_VARIABLE_STAFFING:
      return {
        ...state,
        newValues: {
          ...state.newValues,
          [`${action.payload.variable}`]: {
            ...state.newValues[`${action.payload.variable}`],
            [`${action.payload.id}`]: action.payload.value,
          },
        },
      }
    case GET_HIRING_DECISIONS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesHiringDecision: action.variables,
        },
      }
    case GET_PROMOTIONS:
      return {
        ...state,
        currentValues: {
          ...state.currentValues,
          variablesPromotions: action.variables,
        },
      }
    case CLEAR_NEW_VALUES:
      return {
        ...state,
        newValues: {},
      }
    default:
      return state
  }
}

export default Staffing
