import React, { useContext } from 'react'
import clsx from 'clsx'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import {
  IconButton,
  Divider,
  List,
  Drawer,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { MyContext } from 'common/ConfigProvider'
import { logout } from 'services/authService'
import MainListItems from './listItems'
import style from './style'

const SideBar = () => {
  const state = useContext(MyContext)
  const classes = style()
  const { open, setOpen, setOpenShareModal } = state
  const handleDrawerClose = () => {
    setOpen(false)
  }

  const handleOpenShareLink = () => {
    setOpenShareModal()
  }

  const handleLogout = () => {
    logout()
  }

  return (
    <Drawer
      variant="permanent"
      classes={{
        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
      }}
      open={open}
    >
      <div className={classes.toolbarIcon}>
        <div className={classes.toolbarText}>
          <p>Finch & Mason</p>
        </div>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon className={classes.itemIcon} />
        </IconButton>
      </div>
      <Divider />
      <List className={classes.paddingNone}>
        <MainListItems />
      </List>
      <Divider />
      <List className={classes.paddingNone}>
        <ListItem button onClick={handleOpenShareLink}>
          <ListItemIcon className={classes.itemIcon}>
            <FontAwesomeIcon icon={faLink} />
          </ListItemIcon>
          <ListItemText primary="Join Team Link" className={classes.linkItem} />
        </ListItem>
        <ListItem button onClick={handleLogout}>
          <ListItemIcon className={classes.itemIcon}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </ListItemIcon>
          <ListItemText primary="Logout" className={classes.linkItem} />
        </ListItem>
      </List>
    </Drawer>
  )
}

export default SideBar
