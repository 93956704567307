import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { SALARIES, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { SEGMENTO_FINO_REGION_I } = SALARIES
const { TIME } = HEADER
const { TOTAL_CUSTOMER_CORPORATE_SEGMENT } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: TOTAL_CUSTOMER_CORPORATE_SEGMENT,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[SEGMENTO_FINO_REGION_I],
      },
    ],
  }
}

const SegmentoPreferente = () => {
  const variables = useSelector(state => state.salaries.currentValues.variablesSalariesCharts)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart
      data={returnData(variables, time)}
      titleColor="#758C5E"
      titleChart={TOTAL_CUSTOMER_CORPORATE_SEGMENT}
    />
  ) : null
}
export default SegmentoPreferente
