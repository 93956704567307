import {
  GET_VARIABLES_TABLE_DECISION_SALARIES,
  CHANGE_VARIABLE_SALARIES,
  GET_VARIABLES_SALARIES_LABELS,
  GET_VARIABLES_SALARIES_CHARTS,
  CLEAR_NEW_VALUES,
} from 'constants/actionTypes'
import { getVariablesLastValueByRunId, getVariablesByRunId } from 'services/forio/forio'
import { SALARIES } from 'constants/forioVariables'

const {
  DECISION_DE_SALARIOS_ABS,
  DECISION_DE_SALARIOS_DIRECTORES,
  DECISION_DE_SALARIOS_SOCIOS,
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
  DECISION_BONO_DE_CONTRARACION_ABS,
  DECISION_BONO_DE_CONTRARACION_LATERAL,
  BONO_DE_CONTRATACION_ABS_EN_LA_ECONOMIA_SMOOTHED,
  BONO_DE_CONTRATACION_LATERAL_EN_LA_ECONOMIA_SMOOTHED,
  SALARIOS_ASISTENTES_EN_LA_ECONOMIA_SMOOTHED,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_STAFF,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_DIRECTORES,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_SOCIOS,
  SEGMENTO_FINO_REGION_I,
  SEGMENTO_PURETE_REGION_I,
} = SALARIES

const variablesTableDecisionSalaries = [
  DECISION_DE_SALARIOS_ABS,
  DECISION_DE_SALARIOS_DIRECTORES,
  DECISION_DE_SALARIOS_SOCIOS,
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
  DECISION_BONO_DE_CONTRARACION_ABS,
  DECISION_BONO_DE_CONTRARACION_LATERAL,
]

const getVariablesTableDecisionSalaries = variables => ({
  type: GET_VARIABLES_TABLE_DECISION_SALARIES,
  variables,
})

const fetchVariablesTableDecisionSalaries = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesTableDecisionSalaries)
    dispatch(getVariablesTableDecisionSalaries(variables))
  }
}

const changeSalariesVariable = payload => {
  return { type: CHANGE_VARIABLE_SALARIES, payload }
}

const variablesSalariesLabels = [
  BONO_DE_CONTRATACION_ABS_EN_LA_ECONOMIA_SMOOTHED,
  BONO_DE_CONTRATACION_LATERAL_EN_LA_ECONOMIA_SMOOTHED,
]

const getVariablesLabelsSalaries = variables => ({
  type: GET_VARIABLES_SALARIES_LABELS,
  variables,
})

const fetchVariablesLabelsSalaries = () => {
  return async dispatch => {
    const variables = await getVariablesLastValueByRunId(variablesSalariesLabels)
    dispatch(getVariablesLabelsSalaries(variables))
  }
}

const variablesSalariesCharts = [
  SALARIOS_ASISTENTES_EN_LA_ECONOMIA_SMOOTHED,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_STAFF,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_DIRECTORES,
  SALARIOS_EN_LA_ECONOMIA_SMOOTHED_SOCIOS,
  SEGMENTO_FINO_REGION_I,
  SEGMENTO_PURETE_REGION_I,
]

const getVariablesChartsSalaries = variables => ({
  type: GET_VARIABLES_SALARIES_CHARTS,
  variables,
})

const fetchVariablesChartsSalaries = () => {
  return async dispatch => {
    const variables = await getVariablesByRunId(variablesSalariesCharts)
    dispatch(getVariablesChartsSalaries(variables))
  }
}

const clearNewValues = () => {
  return async dispatch => {
    dispatch({ type: CLEAR_NEW_VALUES })
  }
}

const allFuntionsFetchSalaries = [
  fetchVariablesTableDecisionSalaries,
  fetchVariablesLabelsSalaries,
  fetchVariablesChartsSalaries,
]

export {
  fetchVariablesTableDecisionSalaries,
  allFuntionsFetchSalaries,
  changeSalariesVariable,
  clearNewValues,
}
