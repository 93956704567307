import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Table from 'components/Table'
import { CONSTANTS } from 'constants/constants'
import { SALARIES } from 'constants/forioVariables'
import { changeValue as onChangeValue } from 'common'
import { changeSalariesVariable } from 'actions/salariesActions'

const {
  ASSISTANS_SALARY,
  DIRECTORS_SALARY,
  PARTHERS_SALARY,
  STAFF_SALARY,
  HIRING_BONUS_ASSISTANT,
  HIRING_BONUS_DIRECTORS,
} = CONSTANTS

const {
  DECISION_DE_SALARIOS_ABS,
  DECISION_DE_SALARIOS_DIRECTORES,
  DECISION_DE_SALARIOS_SOCIOS,
  DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
  DECISION_BONO_DE_CONTRARACION_ABS,
  DECISION_BONO_DE_CONTRARACION_LATERAL,
} = SALARIES

const returnData = variables => [
  [ASSISTANS_SALARY, { id: DECISION_DE_SALARIOS_ABS, value: variables[DECISION_DE_SALARIOS_ABS] }],
  [
    DIRECTORS_SALARY,
    { id: DECISION_DE_SALARIOS_DIRECTORES, value: variables[DECISION_DE_SALARIOS_DIRECTORES] },
  ],
  [
    PARTHERS_SALARY,
    { id: DECISION_DE_SALARIOS_SOCIOS, value: variables[DECISION_DE_SALARIOS_SOCIOS] },
  ],
  [
    STAFF_SALARY,
    {
      id: DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE,
      value: variables[DECISION_DE_SALARIOS_PROMEDIO_STAFF_DE_SOPORTE],
    },
  ],
  [
    HIRING_BONUS_ASSISTANT,
    { id: DECISION_BONO_DE_CONTRARACION_ABS, value: variables[DECISION_BONO_DE_CONTRARACION_ABS] },
  ],
  [
    HIRING_BONUS_DIRECTORS,
    {
      id: DECISION_BONO_DE_CONTRARACION_LATERAL,
      value: variables[DECISION_BONO_DE_CONTRARACION_LATERAL],
    },
  ],
]

const columns = changeValue => [
  { type: 'label', name: '' },
  { type: 'inputNumber', name: '', onChange: changeValue },
]

const TableDecisionSalaries = () => {
  const dispatch = useDispatch()
  const variables = useSelector(
    state => state.salaries.currentValues.variablesTableDecisionSalaries,
  )
  const changeValue = onChangeValue(
    'variablesTableDecisionSalaries',
    dispatch,
    changeSalariesVariable,
  )
  return (
    <Table titleTable="Salary policy" columns={columns(changeValue)} data={returnData(variables)} />
  )
}

export default TableDecisionSalaries
