import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { CONSTANTS } from 'constants/constants'
import { CAPACITY } from 'constants/forioVariables'
import { changeVariable } from 'actions/capacityActions'
import { changeValue as onChangeValue } from 'common'
import Title from 'components/TitleTablesValidation'
import LabelValidation from 'components/LabelTitleValidation'

import { validateVariables } from 'common/utils'
import { getVariablesOfTheState } from 'common/states'

const {
  ASSISTANS,
  DIRECTORS,
  PARTHERS,
  TAXES_AND_FINANCES,
  BIDDING_AND_GOVERMENT,
  TRIALS,
  REAL_STATE,
  CORPORATE,
  CAPACITY_TEXT,
} = CONSTANTS

const {
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,

  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,

  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,

  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
  DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
} = CAPACITY

const returnData = (variables, whichColumn) => [
  [
    <LabelValidation
      title={ASSISTANS}
      whichColumn={whichColumn}
      columnValidate="corporateAssitance"
    />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_ASISTENTES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_ASISTENTES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_ASISTENTES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_ASISTENTES_SEGMENTO_FINO
        ],
    },
  ],
  [
    <LabelValidation
      title={DIRECTORS}
      whichColumn={whichColumn}
      columnValidate="corporateDirectors"
    />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_DIRECTORES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_DIRECTORES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_DIRECTORES_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_DIRECTORES_SEGMENTO_FINO
        ],
    },
  ],
  [
    <LabelValidation
      title={PARTHERS}
      whichColumn={whichColumn}
      columnValidate="corporatePartners"
    />,
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO,
      value:
        variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_AIY_REGION_I_SOCIOS_SEGMENTO_FINO],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO,
      value:
        variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ALYG_REGION_I_SOCIOS_SEGMENTO_FINO],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO,
      value:
        variables[
          DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_JUICIOS_REGION_I_SOCIOS_SEGMENTO_FINO
        ],
    },
    {
      id: DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO,
      value:
        variables[DECISION_DE_PERCENTAJE_DE_CAPACIDAD_ASIGNADA_ABR_REGION_I_SOCIOS_SEGMENTO_FINO],
    },
  ],
]

const columns = changeValue => [
  { type: 'label', name: '' },
  { type: 'inputNumber', name: TAXES_AND_FINANCES, onChange: changeValue },
  { type: 'inputNumber', name: BIDDING_AND_GOVERMENT, onChange: changeValue },
  { type: 'inputNumber', name: TRIALS, onChange: changeValue },
  { type: 'inputNumber', name: REAL_STATE, onChange: changeValue },
]

const CapacityDistributionPerProductCorporateSegment = () => {
  const dispatch = useDispatch()

  const changeValue = onChangeValue(
    'variablesCapacityDistributionPerProductCorporateSegment',
    dispatch,
    changeVariable,
  )
  const capacity = useSelector(state => state.capacity.currentValues)
  const variables = capacity.variablesCapacityDistributionPerProductCorporateSegment
  const capacityNewValues = useSelector(state => state.capacity.newValues)
  const validationErrors = validateVariables(
    { ...getVariablesOfTheState(capacityNewValues) },
    CAPACITY_TEXT,
  )
  return (
    <>
      <Table
        titleTable={
          <Title
            title="Capacity distribution per product corporate segment"
            validationText="(Adding all values in a row should be less or equal to 1)"
            isError={
              validationErrors &&
              validationErrors.error &&
              (validationErrors.whichTable === CORPORATE ||
                validationErrors.allTablesWithErrors === true)
            }
          />
        }
        titleColor="#758C5E"
        columns={columns(changeValue)}
        data={returnData(variables, validationErrors.whichColumn)}
      />
    </>
  )
}

export default CapacityDistributionPerProductCorporateSegment
