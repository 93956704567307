import React from 'react'
import { useSelector } from 'react-redux'
import { Avatar } from '@material-ui/core'
import AccessTime from '@material-ui/icons/AccessTime'
import { makeStyles } from '@material-ui/core/styles'
import Chip from '@material-ui/core/Chip'

const useStyle = makeStyles(theme => ({
  chip: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    color: 'black',
  },
  avatar: {
    backgroundColor: 'black',
  },
}))

const LabelTimeSimulation = () => {
  const classes = useStyle()
  const step = useSelector(state => state.header.step)
  const time = `${step} Days`

  return (
    <Chip
      size="medium"
      color="secondary"
      avatar={
        <Avatar className={classes.avatar}>
          <AccessTime />
        </Avatar>
      }
      label={time}
      className={classes.chip}
    />
  )
}

export default LabelTimeSimulation
