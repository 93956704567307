import React from 'react'
import { Typography } from '@material-ui/core'
import { useSelector } from 'react-redux'
import List from 'components/List'
import { CONSTANTS } from 'constants/constants'
import { STAFF } from 'constants/forioVariables'
import { formatVariables } from 'common/utils'

const { NUMBER_OF_PARTHERS, RESIGNATIONS } = CONSTANTS

const { TOTAL_DE_SOCIOS, RENUNCIAS_SOCIOS } = STAFF

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = variables => [
  {
    id: '1-PartnersList',
    label: NUMBER_OF_PARTHERS,
    component: <Typography style={classLabel}>{variables[TOTAL_DE_SOCIOS]}</Typography>,
  },
  {
    id: '2-PartnersList',
    label: RESIGNATIONS,
    component: <Typography style={classLabel}>{variables[RENUNCIAS_SOCIOS]}</Typography>,
  },
]
const PartnersList = () => {
  const variables = useSelector(state => state.staff.variablesPartnersList)
  const formattedVariables = formatVariables(variables)

  return <List items={returnElements(formattedVariables)} />
}
export default PartnersList
