import React from 'react'
import { Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import List from 'components/List'
import InputNumber from 'components/InputNumber'
import { CONSTANTS } from 'constants/constants'
import { MARKETING } from 'constants/forioVariables'
import { changeVariable } from 'actions/marketingActions'
import { changeValue as onChangeValue } from 'common'

const {
  TOTAL_NUMBER_OF_BRANCH_OFFICES,
  OPEN_NEW_BRANCH_OFFICES,
  CLOSE_BRANCH_OFFICES,
  PERCENTAGE_OF_REVENUE_SPENT_IN_MARKETING,
  DAYS_BEFORE_PAYMENT,
} = CONSTANTS

const {
  SUCURSALES_REGION_I,
  DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I,
  DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I,
  DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I,
  DECISION_DE_DIAS_DE_CREDITO_REGION_I,
} = MARKETING

const classLabel = {
  fontSize: '.875rem',
}

const returnElements = (variables, changeValue) => [
  {
    id: '1-MarketingList',
    label: TOTAL_NUMBER_OF_BRANCH_OFFICES,
    component: <Typography style={classLabel}>{variables[SUCURSALES_REGION_I]}</Typography>,
  },
  {
    id: '2-MarketingList',
    label: OPEN_NEW_BRANCH_OFFICES,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I]}
        id={DECISION_DE_APERTURA_DE_SUCURSAL_REGION_I}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '3-MarketingList',
    label: CLOSE_BRANCH_OFFICES,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I]}
        id={DECISION_DE_CIERRE_DE_SUCURSAL_REGION_I}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '4-MarketingList',
    label: DAYS_BEFORE_PAYMENT,
    component: (
      <InputNumber
        defaultValue={variables[DECISION_DE_DIAS_DE_CREDITO_REGION_I]}
        id={DECISION_DE_DIAS_DE_CREDITO_REGION_I}
        change={event => changeValue(event)}
      />
    ),
  },
  {
    id: '5-MarketingList',
    label: PERCENTAGE_OF_REVENUE_SPENT_IN_MARKETING,
    component: (
      <InputNumber
        defaultValue={
          variables[DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I]
        }
        id={DECISION_DE_INVERSION_EN_MERCADEO_COMO_PORCENTAJE_DE_LOS_INGRESOS_REGION_I}
        change={event => changeValue(event)}
        max={1}
        min={0}
      />
    ),
  },
]
const ListElements = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesList', dispatch, changeVariable)
  const variables = useSelector(state => state.marketing.currentValues.variablesList)
  return (
    <List
      items={returnElements(variables, changeValue)}
      titleColor="#9E8585"
      listTitle="Popular market decisions"
    />
  )
}
export default ListElements
