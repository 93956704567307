import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Loading from 'components/Loading'
import { useSelector, useDispatch } from 'react-redux'
import { validateStates } from 'common/states'
import { clearNewValues } from 'actions/salariesActions'
import TableDecision from './TableDecision'
import ListElements from './List'
import { Assistants, Staff, Directors, Socios } from './Graphics'

const useStyles = makeStyles({
  sectionTitle: {},
})

const Salaries = () => {
  const classes = useStyles()

  const dispatch = useDispatch()
  dispatch(clearNewValues())

  const stateSalaries = useSelector(state => state.salaries.currentValues)
  if (validateStates(stateSalaries)) {
    return <Loading />
  }
  return (
    <div>
      <h1>Salaries</h1>
      <Box display="flex" flexWrap="wrap" alignItems="top">
        <Box className="elementWrapper elementToLeft">
          <TableDecision />
        </Box>
        <Box className="elementWrapper elementToRight">
          <ListElements />
        </Box>
      </Box>

      <h2 className={classes.sectionTitle}>Salaries in market</h2>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <Assistants />
        </Box>
        <Box className="elementWrapper elementToRight">
          <Staff />
        </Box>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box className="elementWrapper elementToLeft">
          <Directors />
        </Box>
        <Box className="elementWrapper elementToRight">
          <Socios />
        </Box>
      </Box>
    </div>
  )
}

export default Salaries
