import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { SALARIES, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { SALARIOS_EN_LA_ECONOMIA_SMOOTHED_STAFF } = SALARIES
const { TIME } = HEADER
const { STAFF } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: STAFF,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[SALARIOS_EN_LA_ECONOMIA_SMOOTHED_STAFF],
      },
    ],
  }
}

const Staff = () => {
  const variables = useSelector(state => state.salaries.currentValues.variablesSalariesCharts)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart titleColor="#758C5E" data={returnData(variables, time)} titleChart={STAFF} />
  ) : null
}
export default Staff
