import {
  GET_VARIBLES_PERCEIVED_REPUTATION,
  GET_VARIBLES_PERCEIVED_QUALITY,
  GET_VARIBLES_CLIENT_TO_LAWYER_RATIO,
  CHANGE_VARIABLE_QUALITY,
  CLEAR_VALUES,
} from 'constants/actionTypes'

const initialCapacity = {
  variablesChartPerceivedReputation: {},
  variablesChartPerceivedQuality: {},
  variablesChartClientToLawyerRatio: {},
}

const Quality = (state = initialCapacity, action) => {
  switch (action.type) {
    case CLEAR_VALUES:
      return initialCapacity
    case GET_VARIBLES_PERCEIVED_REPUTATION:
      return {
        ...state,
        variablesChartPerceivedReputation: action.variables,
      }
    case GET_VARIBLES_PERCEIVED_QUALITY:
      return {
        ...state,
        variablesChartPerceivedQuality: action.variables,
      }
    case GET_VARIBLES_CLIENT_TO_LAWYER_RATIO:
      return {
        ...state,
        variablesChartClientToLawyerRatio: action.variables,
      }
    case CHANGE_VARIABLE_QUALITY:
      return {
        ...state,
        [`${action.payload.variable}`]: {
          ...state[`${action.payload.variable}`],
          [`${action.payload.id}`]: action.payload.value,
        },
      }
    default:
      return state
  }
}
export default Quality
