import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'

const useStyles = makeStyles({
  card: {
    backgroundColor: '#3A4149',
    border: '1px solid #23282c',
    boxShadow: 'none',
    color: '#e4e7ea',
    marginBottom: '1.5rem',
  },
  cardTitle: {
    borderBottom: '1px solid #23282c',
    padding: '.75rem 1.25rem',
    backgroundColor: prop => prop.titleColor || '#119E9E',
  },
  title: {
    color: '#e4e7ea',
    fontSize: '.875rem',
    fontWeight: '400',
    margin: '0',
  },
  itemText: {
    fontSize: '.875rem',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
  },
})

const ListItems = ({ items, listTitle, titleColor }) => {
  const classes = useStyles({ titleColor })
  let title = null
  if (listTitle) {
    title = (
      <div className={classes.cardTitle}>
        <h2 className={classes.title}>{listTitle}</h2>
      </div>
    )
  }

  return (
    <Card className={classes.card}>
      {title}
      <CardContent>
        <Box>
          {items.map(item => (
            <Box key={item.id} display="flex" justifyContent="space-between" alignItems="center">
              <Box>
                <p>{item.label}</p>
              </Box>
              <Box style={{ paddingLeft: '10px' }}>{item.component}</Box>
            </Box>
          ))}
        </Box>
      </CardContent>
    </Card>
  )
}

export default ListItems
