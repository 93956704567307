import { makeStyles } from '@material-ui/core/styles'

const footerStyle = makeStyles(() => ({
  footerBox: {
    backgroundColor: '#2b3035',
    color: '#e4e7ea',
    padding: '10px 20px',
    textAlign: 'right',
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif',
    fontSize: '13px',
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
    zIndex: '100',
  },
  footerText: {
    margin: '0',
  },
  footerLink: {
    color: '#e4e7ea',
    textDecoration: 'none',
  },
}))

export { footerStyle }
