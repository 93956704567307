import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { MARKETING, HEADER } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'

const { MARKET_SHARE_FINO_SMOOTHED } = MARKETING
const { TIME } = HEADER
const { CORPORATE_SEGMENT } = CONSTANTS

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: CORPORATE_SEGMENT,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[MARKET_SHARE_FINO_SMOOTHED],
      },
    ],
  }
}

const ChartCorporateSegment = () => {
  const variables = useSelector(
    state => state.marketing.currentValues.variablesChartCorporateSegments,
  )
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart
      data={returnData(variables, time)}
      titleColor="#758C5E"
      titleChart="Corporate Segment"
    />
  ) : null
}
export default ChartCorporateSegment
