import React from 'react'
import { useSelector } from 'react-redux'
import { LineChart } from 'components/Charts'
import { SALARIES, HEADER } from 'constants/forioVariables'

const { SALARIOS_EN_LA_ECONOMIA_SMOOTHED_DIRECTORES } = SALARIES
const { TIME } = HEADER

const returnData = (variables, time) => {
  return {
    labels: time ? time[TIME] : [0],
    datasets: [
      {
        label: `Director's`,
        fill: false,
        borderColor: '#4bc0c0',
        data: variables[SALARIOS_EN_LA_ECONOMIA_SMOOTHED_DIRECTORES],
      },
    ],
  }
}

const Directors = () => {
  const variables = useSelector(state => state.salaries.currentValues.variablesSalariesCharts)
  const time = useSelector(state => state.header.variablesHeader)
  return time && time[TIME] ? (
    <LineChart data={returnData(variables, time)} titleColor="#4C7A1D" titleChart={`Director's`} />
  ) : null
}
export default Directors
