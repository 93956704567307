import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Table from 'components/Table'
import { changeVariable } from 'actions/marketingActions'
import { MARKETING } from 'constants/forioVariables'
import { CONSTANTS } from 'constants/constants'
import { changeValue as onChangeValue } from 'common'

const {
  PRODUCT,
  POPULAR,
  CORPORATE,
  TAXES_AND_FINANCES,
  BIDDING_AND_GOVERMENT,
  TRIALS,
  REAL_STATE,
} = CONSTANTS

const {
  DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
  DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
  DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
  DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
  DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
} = MARKETING

const columns = changeValue => [
  { type: 'label', name: PRODUCT },
  { type: 'inputNumber', name: POPULAR, onChange: changeValue },
  { type: 'inputNumber', name: CORPORATE, onChange: changeValue },
]

const returnData = variables => [
  [
    TAXES_AND_FINANCES,
    {
      id: DECISION_PRECIO_POR_HORA_IY_FRI_PURETE,
      value: variables[DECISION_PRECIO_POR_HORA_IY_FRI_PURETE],
    },
    {
      id: DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO,
      value: variables[DECISION_PRECIO_POR_HORA_IY_REGION_I_FINO],
    },
  ],
  [
    BIDDING_AND_GOVERMENT,
    {
      id: DECISION_PRECIO_POR_HORA_LYG_RI_PURETE,
      value: variables[DECISION_PRECIO_POR_HORA_LYG_RI_PURETE],
    },
    {
      id: DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO,
      value: variables[DECISION_PRECIO_POR_HORA_LYG_REGION_I_FINO],
    },
  ],
  [
    TRIALS,
    {
      id: DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE,
      value: variables[DECISION_PRECIO_POR_HORA_JUCIOS_RI_PURETE],
    },
    {
      id: DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO,
      value: variables[DECISION_PRECIO_POR_HORA_JUCIOS_REGION_I_FINO],
    },
  ],
  [
    REAL_STATE,
    {
      id: DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE,
      value: variables[DECISION_PRECIO_POR_HORA_JUCIOS_BR_RI_PURETE],
    },
    {
      id: DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO,
      value: variables[DECISION_PRECIO_POR_HORA_JUCIOS_BRR_REGION_I_FINO],
    },
  ],
]

const HoursToCarry = () => {
  const dispatch = useDispatch()
  const changeValue = onChangeValue('variablesHourToCarry', dispatch, changeVariable)
  const variables = useSelector(state => state.marketing.currentValues.variablesHourToCarry)
  return (
    <Table
      titleTable="Price per hour ($/hour)"
      columns={columns(changeValue)}
      data={returnData(variables)}
    />
  )
}

export default HoursToCarry
